import i18n from "@/core/plugins/i18n";
import { type City } from "@/stores/auth";

// Получаем API ключ из переменных окружения
const apiKey = import.meta.env.VITE_OPENCAGE_API_KEY;
const opencageURL = 'https://api.opencagedata.com/geocode/v1/json';

// Функция для получения объекта с сообщением о не найденном городе
const cityNotFound = (): City => ({
  name: i18n.global.t('messages.text.error.geoCityNotFound'),
  place: null,
  lat: null,
  lon: null,
  timezone: null,
});

// Функция для получения города по координатам
export const fetchCityByCoords = async (latitude: number, longitude: number): Promise<string> => {
  try {
    const response = await fetch(`${opencageURL}?q=${latitude}+${longitude}&key=${apiKey}`);
    const data = await response.json();

    if (data.status.code === 200 && data.results.length > 0) {
      const components = data.results[0].components;
      return components.city || components.town || components.village || i18n.global.t('messages.text.error.geoCityNotFound');
    } else {
      console.warn(`No city data found for coordinates: (${latitude}, ${longitude})`);
      return i18n.global.t('messages.text.error.geoCityNotFound');
    }
  } catch (err) {
    console.error(`Error when retrieving city data for coordinates: (${latitude}, ${longitude})`, err);
    return i18n.global.t('messages.text.error.geoCityNotFound');
  }
};

// Функция для получения координат по названию города
export const fetchCoordsByCity = async (city: string): Promise<City> => {
  try {
    if (!city) {
      return cityNotFound();
    }

    const response = await fetch(`${opencageURL}?q=${encodeURIComponent(city)}&key=${apiKey}`);
    const data = await response.json();

    if (data.status.code === 200 && data.results.length > 0) {
      let selectedResult = null;
      
      // Обходим все результаты
      for (const result of data.results) {
        const name = result.components.city || result.components.town || result.components.village;
      
        // Если нашли подходящее имя, сохраняем результат и выходим из цикла
        if (name) {
          selectedResult = {
            name,
            place: result.formatted,
            lat: result.geometry.lat.toFixed(4), // Сокращаем до 4 знаков
            lon: result.geometry.lng.toFixed(4), // Сокращаем до 4 знаков
            timezone: result.annotations.timezone.name
          };
          break; // Прекращаем поиск, так как нашли подходящий результат
        }
      }
      
      // Если нашли подходящий результат, возвращаем его
      if (selectedResult) {
        return selectedResult;
      } else {
        // Если ни один результат не подошел, обрабатываем ошибку
        return cityNotFound();
      }
    } else {
      console.warn(`No coordinates found for city: ${city}`);
      return cityNotFound();
    }
  } catch (err) {
    console.error(`Error when getting coordinates by city name: ${city}`, err);
    return cityNotFound();
  }
};

// Функция для проверки, являются ли значения координат корректными числами
export const isValidCoords = (value: number | null): boolean => {
  return typeof value === 'number' && isFinite(value);
};
