<template>
  <div class="d-flex flex-row"> 
    <div
      v-if="hasWishPrice"
      :class="wishPriceClass"
      v-tooltip data-bs-custom-class="tooltip-inverse"
      :title="$t('pages.items.filter.wishprice')"
    >
      <KTIcon icon-name="wallet" icon-class="fs-4 me-2" :class="iconClass" />
      <PriceFormatter :price="item.wishprice" />
    </div>
    <div v-else-if="!hasPricePurchase" :class="wishPriceClass">
      <KTIcon icon-name="wallet" icon-class="fs-4 me-2 text-gray-400" />
      <span class="text-nowrap fs-7 text-gray-400">
        {{ $t('pages.items.priceUnspecified') }}
      </span>
    </div>
    
    <div
      v-if="hasPricePurchase"
      :class="{
        'd-flex align-items-center text-info': true,
        'ms-5': hasWishPrice
      }"
      v-tooltip data-bs-custom-class="tooltip-inverse"
      :title="$t('actions.purchased')"
    >
      <KTIcon icon-name="basket-ok" icon-class="fs-4 me-2 text-info" />
      <PriceFormatter :price="item.price_purchase" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { useItemStore } from '@/stores/item';

import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

export default defineComponent({
  name: 'wish-price-display',
  components: {
    PriceFormatter,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const itemStore = useItemStore();
    
    const hasWishPrice = computed(() => props.item.wishprice > 0);
    const hasPricePurchase = computed(() => props.item.price_purchase > 0);
    
    const isSuccess = computed(() =>
      itemStore.priceComparison ? itemStore.priceComparison(props.item) : false
    );

    const wishPriceClass = computed(() => ({
      'd-flex align-items-center': true,
      'text-success': isSuccess.value,
      'text-gray-500': !isSuccess.value,
    }));
    
    const iconClass = computed(() => ({
      'text-success': isSuccess.value,
      'text-gray-500': !isSuccess.value,
    }));

    return {
      hasWishPrice,
      hasPricePurchase,
      wishPriceClass,
      iconClass,
    };
  },
});
</script>
