<template>
  <!--begin::Login sessions-->
  <div class="card mb-5 mb-lg-10">
      <!--begin::Card header-->
      <div class="card-header min-h-60px">
          <!--begin::Heading-->
          <h3 class="card-title fw-bold m-0">
            {{ $t('pages.account.layoutLogs.loginSessions') }}
          </h3>
          <!--end::Heading-->
          
          <!--begin::Toolbar-->
          <div class="card-toolbar">
            <div class="my-1 ms-4">
              <!--begin::Select-->
              <select
                v-model="selectedPeriod"
                @change="fetchSessionsData"
                class="form-select form-select-sm form-select-solid w-100px"
                data-hide-search="true"
              >
                <option value="1">{{ $t('pages.account.layoutLogs.select.day') }}</option>
                <option value="7">{{ $t('pages.account.layoutLogs.select.week') }}</option>
                <option value="30">{{ $t('pages.account.layoutLogs.select.month') }}</option>
                <option value="365">{{ $t('pages.account.layoutLogs.select.year') }}</option>
              </select>
              <!--end::Select-->
            </div>
          </div>
          <!--end::Toolbar-->
      </div>
      <!--end::Card header-->
      
      <!--begin::Card body-->
      <div class="card-body p-0 mx-9">
        <Datatable
          v-if="!isLoading || userStore.sessions.length > 0"
          class="table align-middle table-row-bordered gy-4 gs-0"
          :header="tableHeader"
          :data="userStore.sessions"
          :items-per-page="itemsPerPage"
          :items-per-page-dropdown-enabled="itemsPerPageDropdown"
          :preload-count="10"
          :emptyTableText="$t('dataTable.emptyTable')"
          @on-items-per-page-change="handleItemsPerPage"
        >
          <template v-slot:device="{ row: item }">
            <div class="text-gray-800 font-normal">
              <div class="d-flex align-items-center gap-4">
                <i :class="`ki-outline ki-${getDeviceIcon(item.uaData?.device)} fs-2qx text-gray-500 `"></i>
                <div class="d-flex flex-column">
                  <span class="fs-6 text-gray-800">{{ item.uaData?.device?.model }}</span>
                  <span class="fs-7 text-gray-600">{{ item.uaData?.os?.name }} {{ item.uaData?.os?.version }}</span>
                </div>
              </div>
            </div>
          </template>

          <template v-slot:type="{ row: item }">
            <span
              :class="[
                'fs-8 fw-semibold badge py-2 px-3',
                item.type === 'USER_AUTHORIZE' ? 'badge-light-success' :
                item.type === 'USER_PASSWORD_CHANGED' ? 'badge-light-info' :
                item.type === 'USER_EDIT' ? 'badge-light-primary' : 'badge-light-dark'
              ]"
            >
              {{ t(`pages.account.layoutLogs.type_text.${item.type}`) }}
            </span>
          </template>
          
          <template v-slot:location="{ row: item }">
            <div class="d-flex flex-column">
              <div class="d-flex align-items-center">
                <img
                  :src="`/media/flags/${item.country.toLowerCase()}.svg`"
                  class="symbol symbol-circle w-15px h-15px me-2 bg-secondary"
                  alt=""
                  @error="handleImageError"
                />
                <span
                  v-if="item.country"
                  class="fs-6 text-gray-800">{{ item.country }}</span>
              </div>
              <div class="d-flex flex-column">
                <span
                  v-if="item.regionCode && item.city"
                  class="fs-7 text-gray-600">{{ item.regionCode }}, {{ item.city }}</span>
                <span
                  v-else-if="item.city"
                  class="fs-7 text-gray-600">{{ item.city }}</span>
              </div>
            </div>
          </template>

          <template v-slot:browser="{ row: item }">
            <div class="d-flex flex-column">
              <span class="fs-6 text-gray-800">{{ item.uaData?.browser?.name }}</span>
              <span class="fs-7 text-gray-600">ver. {{ item.uaData?.browser?.version }}</span>
            </div>
          </template>

          <template v-slot:ip="{ row: item }">
            <span class="fs-6 font-monospace bg-light text-gray-800 p-3 rounded">{{ item.ip }}</span>
          </template>

          <template v-slot:date="{ row: item }">
            <div class="d-flex flex-column">
              <DateFormatter 
                :date="item.datetime" 
                :format="'dynamicDate'" 
                class="fs-6 text-gray-800"
              />
              <DateFormatter 
                :date="item.datetime" 
                :format="'atTime'" 
                class="fs-7 text-gray-600"
              />
            </div>
          </template>
        </Datatable>
        
        <Placeholder
          v-else
          :length="5"
          :table-header="tableHeader"
          :items-per-page="itemsPerPage"
        />
      </div>
      <!--end::Card body-->
  </div>
  <!--end::Login sessions-->
</template>

<script lang="ts" setup>
import { ref, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useConfigStore } from "@/stores/config";
import { useI18n } from "vue-i18n";
import { UAParser } from 'ua-parser-js';

import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import DateFormatter from '@/components/formatters/DateFormatter.vue';
import Placeholder from '@/components/placeholder/PagesAccountLogs.vue';

const { t } = useI18n();
const userStore = useAuthStore();
const bodyStore = useConfigStore();

const isLoading = ref(true);
const itemsPerPage = ref(bodyStore.getLayoutConfig('datatable.itemsPerPage'));
const tableHeader = ref([
  {
    columnName: t('pages.account.layoutLogs.titleTable.device'),
    columnLabel: "device",
    sortEnabled: false,
    columnClass: 'min-w-175px'
  },
  {
    columnName: t('pages.account.layoutLogs.titleTable.type'),
    columnLabel: "type",
    sortEnabled: true,
    columnClass: 'min-w-150px'
  },
  {
    columnName: t('pages.account.layoutLogs.titleTable.browser'),
    columnLabel: "browser",
    sortEnabled: false,
    columnClass: 'min-w-150px'
  },
  {
    columnName: t('pages.account.layoutLogs.titleTable.location'),
    columnLabel: "location",
    sortEnabled: false,
    columnClass: 'min-w-150px'
  },
  {
    columnName: t('pages.account.layoutLogs.titleTable.ip'),
    columnLabel: "ip",
    sortEnabled: true,
    columnClass: 'min-w-125px'
  },
  {
    columnName: t('pages.account.layoutLogs.titleTable.date'),
    columnLabel: "date",
    sortEnabled: false,
    columnClass: 'min-w-125px me-7'
  },
]);

const itemsPerPageDropdown = computed(() => {
  return userStore.sessions?.length > 10;
});

const handleItemsPerPage = (val) => {
  bodyStore.setLayoutConfigProperty("datatable.itemsPerPage", val);
};

const getDeviceIcon = (device: { type?: string; vendor?: string; model?: string } = {}): string => {
  const { type, vendor, model } = device;

  if (!type) {
    // Если тип устройства не указан, принимаем решение на основе vendor и model
    if (vendor?.toLowerCase() === 'apple' && model?.toLowerCase() === 'macintosh') {
      return 'laptop'; // Возвращаем иконку для ноутбука/настольного ПК Apple
    }
  }

  // Проверяем стандартные типы устройств
  switch (type?.toLowerCase()) {
    case 'mobile':
      return 'phone';
    case 'tablet':
      return 'tablet';
    case 'laptop':
      return 'laptop';
    case 'desktop':
    case 'screen':
      return 'screen';
    default:
      return 'devices';
  }
};

// Функция для обработки user-agent с помощью UAParser
const browserUserAgent = (ua) => {
  const parser = new UAParser(ua);
  // Полный объект с информацией о браузере
  return parser.getResult(); 
};

// Переменная для выбранного периода
const selectedPeriod = ref(7);

// Функция для получения и обработки данных сессий
const fetchSessionsData = async () => {
  await userStore.fetchSessions(selectedPeriod.value); // Передаем выбранный период

  // Обходим каждую сессию и заменяем ua на результат парсинга
  userStore.sessions = userStore.sessions.map((session) => {
    const uaData = browserUserAgent(session.ua);
    return {
      ...session, // Сохраняем все старые данные сессии
      uaData
    };
  });

  isLoading.value = false;
};

// Функция для обработки ошибок загрузки изображения
const handleImageError = (event: Event) => {
  const img = event.target as HTMLImageElement;
  img.src = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABAQMAAAAl21bKAAAAA1BMVEUAAACnej3aAAAAAXRSTlMAQObYZgAAAApJREFUCNdjYAAAAAIAAeIhvDMAAAAASUVORK5CYII=';
};

onMounted( async() => {
  await fetchSessionsData();
});
</script>
