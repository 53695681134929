<template>
  <textarea v-if="mode === 'textarea'" ref="tagifyInput" />
  <input v-else ref="tagifyInput" />
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, onBeforeUnmount } from 'vue';
import Tagify from '@yaireo/tagify';

export default defineComponent({
  name: 'tagify-component',
  props: {
    mode: {
      type: String,
      default: 'input'
    },
    settings: {
      type: Object,
      default: () => ({})
    },
    modelValue: {
      type: [String, Array],
      default: () => []
    },
    tempWords: {
      type: String,
      default: ''
    },
    isTagifyUpdated: {
      type: Boolean,
      default: false
    },
  },
  emits: ['update:modelValue', 'focus', 'blur', 'updateTagify'],
  setup(props, { emit, expose }) {
    const tagifyInstance = ref<Tagify | null>(null);
    const tagifyInput = ref(null);
    
    // Функция нормализации значения
    const normalizeValue = (value: any): { value: string }[] => {
      if (Array.isArray(value)) {
        // Если массив, просто возвращаем его
        return value;
      }
      if (typeof value === 'string') {
        try {
          // Проверяем, является ли строка JSON, и парсим её
          const parsed = JSON.parse(value);
          if (Array.isArray(parsed)) {
            // Если это массив объектов, возвращаем его напрямую
            return parsed.map((item: any) => ({
              value: item.value ? String(item.value).trim() : '',
            }));
          }
        } catch (e) {
          // Если это не JSON, обрабатываем как обычную строку
        }
    
        // Если строка не JSON, разбиваем по запятым
        return value
          .split(',')                  // Разбиваем строку по запятым
          .map(tag => tag.trim())      // Убираем лишние пробелы
          .filter(tag => tag)          // Исключаем пустые строки
          .map(tag => ({ value: tag })); // Преобразуем в массив объектов
      }
      return [];
    };
    
    const filterNonTemporaryTags = (tags: { value: string }[]): { value: string }[] => {
      return tags.filter(
        (tag) => !tagifyInstance.value?.getTagElmByValue(tag.value)?.classList.contains('temporary_tag')
      );
    };
    
    // Обработчик обновления modelValue
    const updateModelValue = (e: CustomEvent) => {
      const nonTemporaryTags = filterNonTemporaryTags(normalizeValue(e.detail.value));
      emit('update:modelValue', nonTemporaryTags);
    };
    
    // Функция обновления Tagify
    const updateTagify = (values: number | string | string[]) => {
      if (!tagifyInstance.value) return;
    
      // Преобразуем строку tempWords в массив объектов тегов
      const newTags = props.tempWords
        .split(',')
        .map((tag) => ({
          value: tag.trim(), // Убираем лишние пробелы
          readonly: true,
          class: 'temporary_tag opacity-75', // Помечаем как временные
          style: '--tag-bg: var(--bs-success); --tag-text-color: var(--bs-white)'
        }));
    
      // Фильтруем "не временные теги"
      const nonTemporaryTags = filterNonTemporaryTags(normalizeValue(values));
    
      // Добавляем временные теги только для визуального отображения
      tagifyInstance.value.loadOriginalValues([...newTags, ...nonTemporaryTags], true);
    
      emit('updateTagify', true);
    };
    
    onMounted(() => {
      // Инициализация Tagify с настройками
      tagifyInstance.value = new Tagify(tagifyInput.value, props.settings);

      // Установка обработчиков для событий
      tagifyInstance.value.on('change', updateModelValue);
      tagifyInstance.value.on('remove', updateModelValue);
      
      tagifyInstance.value.on('focus', () => { emit('focus') });
      tagifyInstance.value.on('blur', () => { emit('blur') });
      
      updateTagify(props.modelValue); // Обновляем теги
    });
    
    // Следим за изменениями modelValue и обновляем Tagify
    watch(() => props.modelValue, (newVal, oldVal) => {
      const normalizedNewVal = normalizeValue(newVal);
      const normalizedOldVal = normalizeValue(oldVal);
      
      // Сравнение нормализованных значений
      if (JSON.stringify(normalizedNewVal) !== JSON.stringify(normalizedOldVal)) {
        updateTagify(normalizedNewVal);
      }
    });
    
    // Следим за изменениями tempWords, isTagifyUpdated и обновляем Tagify
    watch(() => [props.tempWords, props.isTagifyUpdated], () => {
      updateTagify(props.modelValue);
    });

    onBeforeUnmount(() => {
      // Уничтожаем экземпляр Tagify при размонтировании компонента
      tagifyInstance.value?.destroy();
    });

    // Метод для добавления тегов
    const addTags = (tags: string | string[]) => {
      tagifyInstance.value?.addTags(tags);
    };
    
    // Сброс значений Tagify
    const resetTags = (newVal) => {
      tagifyInstance.value?.removeAllTags(); // Удаляем все теги
      tagifyInstance.value?.loadOriginalValues(newVal); // Загрузка начального значения
    };
    
    // Делаем метод доступным для родительского компонента
    expose({ addTags, resetTags });

    return {
      tagifyInput
    };
  }
});
</script>
