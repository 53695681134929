<template>
  <apexchart
    :class="className"
    ref="chartRef"
    type="area"
    :options="chart"
    :series="series"
    :height="chartHeight"
  ></apexchart>
</template>

<script lang="ts">
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useThemeStore } from "@/stores/theme";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatDatesToLocale } from "@/utils/helpers/formatters";
import type { ApexOptions } from "apexcharts";
import type VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  name: "platform-chart",
  props: {
    className: { type: String, required: false },
    chartColorItem: { type: String, required: false },
    chartColorLink: { type: String, required: false },
    chartHeight: { type: String, required: false },
    chartData: { type: Object, required: true },
  },
  setup(props) {
    const { t, locale } = useI18n();
    const chartRef = ref<typeof VueApexCharts | null>(null);
    const chart = ref<ApexOptions>({});
    const series = ref([
      { name: t('charts.platform.items'), data: []},
/*       { name: t('charts.platform.items'), data: []}, */
    ]);
    const store = useThemeStore();
    const themeMode = computed(() => store.mode);
    
    const computedChartOptions = computed(() => chartOptions(
      props.chartColorItem,
      props.chartColorLink,
      props.chartHeight
    ));
    
    const updateChartOptions = () => {
      if (!chartRef.value) return;
      if (props.chartData?.item) {
        chartRef.value.updateOptions(computedChartOptions.value);
      }
    };
    
    const fetchChartData = () => {
      if (props.chartData.item?.length > 0) {
        const objDates = formatDatesToLocale(
          props.chartData.date,
          locale.value,
          { month: 'long' }
        );
        
        series.value[0].name = t('charts.platform.items'); // Динамическое обновление имени
        series.value[0].data = props.chartData.item.map((item, index) => ({
          x: objDates[index], y: item,
        }));
      }
      
/*       if (props.chartData.link?.length > 0) {
        series.value[0].name = t('charts.platform.items'); // Динамическое обновление имени
        series.value[1].data = props.chartData.link?.map((link, index) => ({
          x: props.chartData.date[index],
          y: link,
        }));
      } */

      setTimeout(() => updateChartOptions, 100); // Задержка в 100 миллисекунд
    };

    onMounted(() => {
      chart.value = computedChartOptions.value;
      fetchChartData();
    });
    
    watch(() => props.chartData, () => {
      fetchChartData();
    }, { deep: true });
    
    watch(() => locale.value, () => {
      fetchChartData();
    });
    
    watch(themeMode, () => {
      updateChartOptions();
    });
    
    return {
      chart,
      chartRef,
      series,
    };
  },
});

const chartOptions = (
  colorItem: string = "primary",
  colorLink: string = "success",
  height: string = "auto",
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-800");
  const strokeColor = getCSSVariableValue("--bs-gray-300");
  const baseColorItem = getCSSVariableValue(`--bs-${colorItem}`);
  const lightColorItem = getCSSVariableValue(`--bs-${colorItem}-light`);
  const baseColorLink = getCSSVariableValue(`--bs-${colorLink}`);
  const lightColorLink = getCSSVariableValue(`--bs-${colorLink}-light`);

  return {
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: height,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      sparkline: {
        enabled: true,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0.8,
        opacityFrom: 0.6,
        opacityTo: 0.4,
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseColorItem, baseColorLink],
    },
    xaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        show: false,
        position: "front",
        stroke: {
          color: strokeColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: false,
      },
    },
    yaxis: {
      labels: {
        show: false,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    colors: [baseColorItem, baseColorLink],
    markers: {
      colors: [lightColorItem, lightColorLink],
      strokeColors: [baseColorItem, baseColorLink],
      strokeWidth: 3,
    },
  };
};
</script>
