import { defineStore } from 'pinia';
import ApiService from '@/core/services/ApiService';
import { handlerApiError, updateOrAddItems } from '@/utils/helpers/storeHelpers';

export const useNotifyStore = defineStore('notify', {
  state: () => ({
    items: [] as any[],
    errors: [] as string[],
    isLooked: false as boolean,
  }),

  actions: {
    setLooked(value: boolean) {
      this.isLooked = value;
    },

    async fetchData(params: FetchParams, refresh: boolean = false): Promise<void> {
      try {
        const { data } = await ApiService.get('pulse.table.notify.list.json', params);

        const items = data.result || [];
        
        // Обновление массива `items` с данными, полученными из API.
        refresh ? (this.items = items) : updateOrAddItems(this.items, items, 'id');
        
        // Проверка элементов на наличие looked, отличного от '1'
        if (this.items.some(item => item.looked !== '1')) {
          this.setLooked(true);
        }
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },
    
    async checkView() {
      const ids = this.items.map(item => item.id);
      await ApiService.post('pulse.table.notify.check', { ids });
    },

    async clearNotify(params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await this.performDeleteRequest("pulse.table.notify.clean", params, localizedError);
    },
    
    async performDeleteRequest(url: string, params: FetchParams, localizedError: string = ''): Promise<boolean> {
      return await ApiService.delete(url, params)
        .then(({ data }) => {
          return data.result ? true : false;
        })
        .catch(error => handlerApiError(this.errors, error, localizedError));
    },
  },
});
