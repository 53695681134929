<template>
  <div class="kt-terms">
    <!--begin::Button-->
    <span
      class="btn btn-sm btn-light-body btn-text-gray-600 btn-active-text-primary fw-semibold rotate"
      data-kt-menu-trigger="click"
      data-kt-menu-attach="parent"
      data-kt-menu-placement="top-end"
    >
      <span class="me-1">
        {{ $t('info') }}
      </span>
      <span class="d-flex flex-center rotate-180 w-15px">
        <KTIcon icon-name="down" icon-class="fs-5 text-muted m-0" />
      </span>
    </span>
    <!--end::Button-->

    <!--begin::Menu-->
    <div class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-state-bg-light-primary fw-semibold py-4 fs-base w-auto z-index-3" data-kt-menu="true">
      <div class="menu-item px-3">
        <div
          class="menu-link ps-3 pe-5 py-2"
        >
          <span class="menu-icon" data-kt-element="icon">
            <KTIcon icon-name="briefcase" icon-class="fs-2" />
          </span>
          <span class="menu-title">{{ $t('about') }}</span>
        </div>
      </div>
  
      <div
        v-if="!isChaportLoading"
        class="menu-item px-3"
      >
        <div
          class="menu-link ps-3 pe-5 py-2"
          @click="handleChaportClick"
        >
          <span class="menu-icon" data-kt-element="icon">
            <KTIcon icon-name="support-24" icon-class="fs-2" />
          </span>
          <span class="menu-title">{{ $t('support') }}</span>
        </div>
      </div>
  
      <div class="menu-item px-3">
        <div
          class="menu-link ps-3 pe-5 py-2"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_user_agreement"
        >
          <span class="menu-icon" data-kt-element="icon">
            <KTIcon icon-name="security-user" icon-class="fs-2" />
          </span>
          <span class="menu-title">{{ $t('userAgreement') }}</span>
        </div>
      </div>
  
      <div class="menu-item px-3">
        <div
          class="menu-link ps-3 pe-5 py-2"
          data-bs-toggle="modal"
          data-bs-target="#kt_modal_privacy_policy"
        >
          <span class="menu-icon" data-kt-element="icon">
            <KTIcon icon-name="shield" icon-class="fs-2" />
          </span>
          <span class="menu-title">{{ $t('privacyPolicy') }}</span>
        </div>
      </div>
    </div>
    <!--end::Menu-->
  </div>
</template>

<script lang="ts" setup>
import { onMounted } from "vue";
import { initializeChaport, isChaportLoading, handleChaportClick } from "@/core/plugins/chaport";

onMounted(() => {
  initializeChaport(); // Инициализация Chaport
});
</script>
