import { usePriceChartStore } from '@/stores/priceChart';
import { usePushPullStore } from '@/stores/pushPull';
import { useConfigStore } from '@/stores/config';
import { useNotifyStore } from '@/stores/notify';
import { useItemStore } from "@/stores/item";
import { updateOrAddItems } from '@/utils/helpers/storeHelpers';

export function handlePullEvent(data: any, route: any) {
  switch (data.text?.command) {
    
    case 'new_message':
      console.log('Получено новое сообщение:', data.text.params);
      break;
    
    // Истечение срока действия канала, сервер сообщает о необходимости переподключения
    case 'channel_expire':
      if (data.text.params?.action === 'reconnect') {
        updateChannelWebSocket(data.text.params?.new_channel);
      }
      break;

    // Обновление аналитики из cron/search_prices_and_notify.php
    case 'new_analytics':
      updateAnalyticsData(data.text.params, route);
      break;
      
    // Обновление уведомлений из cron/search_prices_and_notify.php
    case 'new_notify':
      updateNotifyData(data.text.params);
      break;
      
    // Обновление данных поиска из rest/parser.php
    case 'update_items':
    // Обновление данных поиска из cron/search_image_load.php
    case 'update_images_search':
      updateSearchData(data.text.params, route);
      break;
      
    // Обновление данных группы из cron/search_group_image_load.php
    case 'update_images_or_descr_group':
      updateGroupData(data.text.params, route);
      break;

    default:
      console.warn('Неизвестная команда:', data.text?.command);
  }
}

// Обновление аналитики
function updateAnalyticsData(params: any, route: any) {
  const dataChart = usePriceChartStore();
  const bodyStore = useConfigStore();
  const period = bodyStore.getLayoutConfig('analytic.period');
  
  const searchId = Number(params.search_id);
  const routeId = Number(route.params?.id);
  const isMatchingId = routeId === searchId;
  const isAnalyticsPage = route.name === 'search-id-analytics';

  // Проверяем, что находимся на странице analytics, данные на которой нужно обновить
  if (isMatchingId && isAnalyticsPage) {
    dataChart.fetchData({ id: routeId, period });
  }
}

// Обновление уведомлений
function updateNotifyData(params: any) {
  const notifyStore = useNotifyStore();
  
  if (params.id) {
    // Запрашиваем элемент с `id`
    notifyStore.fetchData({ update_id: params.id });
  } else {
    // Получаем максимальный `id` из уже загруженных элементов
    const maxId = notifyStore.items.length > 0 
      ? Math.max(...notifyStore.items.map(item => parseInt(item.id, 10))) 
      : 0;
    // Запрашиваем только новые элементы с `id` больше `maxId`
    notifyStore.fetchData({ last_id: maxId });
  }
}

// Обновление данных поиска
function updateSearchData(params: any, route: any) {
  const itemStore = useItemStore();
  
  const isSearchPage = route.name === 'search';
  const isSearchIdListPage = route.name === 'search-id-list';
  const searchId = Number(params.search_id);
  const routeId = Number(route.params?.id);
  const isMatchingId = routeId === searchId;

  // Проверяем, что находимся на странице search или search-id-list, данные на которой нужно обновить
  if (isSearchPage || (isSearchIdListPage && isMatchingId)) {
    itemStore.fetchSearchPartData({ id: searchId });
  }
}

// Обновление данных поиска
function updateGroupData(params: any, route: any) {
  const itemStore = useItemStore();
  
  // Проверяем, что находимся на странице groups
  if (route.name === 'groups' && params.data?.id) {
    updateOrAddItems(itemStore.groups, [params.data], 'id');
  }
}

// Обновление канала и переподключение WebSocket
function updateChannelWebSocket(newChannel: any) {
  const pushPullStore = usePushPullStore();

  if (newChannel?.id) {
    pushPullStore.reconnectWebSocket(newChannel);
  }
}