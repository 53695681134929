<template>
  <label class="form-label fw-semibold">{{ $t('pages.items.filter.groups') }}:</label>
  <Multiselect
    class="py-1"
    name="group"
    :placeholder="$t('pages.items.filter.allGroups')"
    :options="transformedGroups"
    :model-value="modelValue"
    :noOptionsText="$t('pages.items.modal.placeholder.group.emptyList')"
    :noResultsText="$t('pages.items.modal.placeholder.group.notFound')"
    @update:model-value="updateModelValue"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <div class="symbol symbol-25px me-3">
          <DynamicSymbol :title="value.label" :image="value.image" fontSizeClass="fs-5" />
        </div>
        {{ value.label }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <div class="symbol symbol-25px me-3">
        <DynamicSymbol :title="option.label" :image="option.image" fontSizeClass="fs-5" />
      </div>
      {{ option.label }}
    </template>
  </Multiselect>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import Multiselect from '@vueform/multiselect';
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';

const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  groups: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits({
  'update:modelValue': (value: string | null) => true,
});

// Преобразуем группы для использования в компоненте Multiselect
const transformedGroups = computed(() => 
  props.groups.map(item => ({
    value: item.id,
    label: item.title,
    image: item.image,
  }))
);

const updateModelValue = (value: string | null) => {
  emit('update:modelValue', value ?? "0");
};
</script>