<template>
  <div v-if="platform && hasLinks" class="mt-2">
    <div class="text-gray-600 fs-7">
      {{ platformTitle }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'platform-info',
  props: {
    platform: {
      type: Object,
      required: true,
    },
    platformId: {
      type: String,
      required: true,
    },
    getPlatformTitle: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const hasLinks = computed(() =>
      props.platform.links && Object.values(props.platform.links).some(value => value?.length > 0)
    );

    const platformTitle = computed(() => props.getPlatformTitle(props.platformId));

    return {
      hasLinks,
      platformTitle,
    };
  },
});
</script>
