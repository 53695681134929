import { ref, watch } from "vue";
import { useAuthStore } from "@/stores/auth";

export const isChaportLoading = ref(true);

/**
 * Инициализация Chaport с настройкой виджета и обработкой событий.
 * @returns {Promise<void>}
 */
export async function initializeChaport(): Promise<void> {
  const userStore = await useAuthStore();

  // Следим за изменением ID пользователя
  watch(
    () => userStore.user?.id,
    async (newId, oldId) => {
      try {
        if (!window.chaport || typeof window.chaport.on !== "function") {
          return;
        }
        if (newId !== oldId) {
          window.chaport.stopSession();
          if (userStore.user?.chaport?.visitor_id && userStore.user?.chaport?.token) {
            window.chaport.q("startSession", {
              visitorId: userStore.user.chaport.visitor_id,
              token: userStore.user.chaport.token,
            });
          } else {
            window.chaport.startSession();
          }
        }
      } catch (error) {
        console.error("Error during Chaport session management:", error.message);
      }
    },
    { immediate: true } // Запуск обработчика сразу после привязки
  );

  return new Promise((resolve, reject) => {
    try {
      (function (w, d, v3) {
        w.chaportConfig = {
          appId: "67840434be22b575546697d3",
          launcher: { show: false },
          history: false, // browser history
          visitor: {
            name: `${userStore.user?.name ?? ""} ${userStore.user?.last_name ?? ""}`,
            email: userStore.user?.email ?? "",
            phone: userStore.user?.mobile_phone ?? "",
            custom: {
              id: userStore.user?.id ?? "",
              subscriptionPlan: userStore.user?.subscription?.type ?? "",
              place: userStore.user?.city?.place ?? "",
              timezone: userStore.user?.city?.timezone ?? "",
            },
          },
          devices: {
            configs: {
              mobile: {
                appearance: {
                  position: ['right', -6, 80],
                },
              },
            },
          },
          init: {
            event: 'load',
            maxDelay: 5000,
          },
          customOptions: { enableLogging: true },
        };
        
        if (userStore.user?.chaport?.visitor_id && userStore.user?.chaport?.token) {
          w.chaportConfig.session = {
            user: {
              visitorId: userStore.user.chaport.visitor_id,
              token: userStore.user.chaport.token,
            },
          };
        }

        if (w.chaport) {
          isChaportLoading.value = false;
          return resolve();
        }

        v3 = w.chaport = {};
        v3._q = [];
        v3._l = {};
        v3.q = function () {
          v3._q.push(arguments);
        };
        v3.on = function (e, fn) {
          if (!v3._l[e]) v3._l[e] = [];
          v3._l[e].push(fn);
        };

        const s = d.createElement("script");
        s.type = "text/javascript";
        s.async = true;
        s.src = "https://app.chaport.ru/javascripts/insert.js";
        const ss = d.getElementsByTagName("script")[0];
        ss.parentNode.insertBefore(s, ss);
      })(window, document);

      window.chaport.on('ready', function (data) {
        if (data.visitorId && userStore.user?.id && !userStore.user?.chaport?.visitor_id) {
          userStore.updateUserСhaport({'UF_CHAPORT_VISITOR_ID': data.visitorId});
        }

        isChaportLoading.value = false; // Завершение загрузки
        resolve();
      });
    } catch (error) {
      console.error("Error during Chaport initialization:", error);
      isChaportLoading.value = false; // Завершение загрузки при ошибке
      reject(error);
    }
  });
}

/**
 * Обработчик клика по Chaport-виджету.
 */
export async function handleChaportClick() {
  if (!window.chaport) {
    await initializeChaport();
  }
  
  if (window.chaport && typeof window.chaport.open === "function") {
    window.chaport.toggle();
  } else {
    console.error(
      "Chaport widget is not initialized or open function is unavailable"
    );
  }
}