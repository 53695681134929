<template>
  <Datatable
    aria-hidden="true"
    class="table align-middle table-row-dashed gy-3 placeholder-glow"
    :header="tableHeader"
    :data="placeholderItems"
    :items-per-page="itemsPerPage"
  >
    <template v-slot:title>
      <div class="d-flex align-items-center">
        <div class="symbol symbol-75px placeholder bg-secondary">
          <span class="symbol-label placeholder bg-secondary"></span>
        </div>
        <div class="d-flex flex-column w-100 gap-5 ms-5">
          <div class="rounded-1 placeholder bg-secondary placeholder-lg col-8"></div>
          <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4"></div>
          <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4"></div>
        </div>
      </div>
    </template>
  
    <template v-slot:price>
      <div class="d-flex align-items-end flex-column gap-4">
        <div class="rounded-1 placeholder bg-secondary placeholder-lg col-7"></div>
        <div class="rounded-1 placeholder bg-secondary placeholder-sm col-7"></div>
        <div class="rounded-1 placeholder bg-secondary placeholder-sm col-4"></div>
      </div>
    </template>
  
    <template v-slot:chart>
      <div class="rounded-1 placeholder bg-secondary placeholder-lg col-6 h-50px w-50px"></div>
    </template>
  
    <template v-slot:platform>
      <div class="d-flex align-items-end flex-column gap-4">
        <div class="rounded-1 placeholder bg-secondary col-5"></div>
        <div class="rounded-1 placeholder bg-secondary col-7"></div>
      </div>
    </template>
  
    <template v-slot:count>
      <div class="d-flex flex-column gap-4">
        <div class="rounded-1 placeholder bg-secondary col-6"></div>
        <div class="rounded-1 placeholder bg-secondary col-6"></div>
      </div>
    </template>
  
    <template v-slot:updated>
      <div class="d-flex flex-column gap-4">
        <div class="rounded-1 placeholder bg-secondary col-6"></div>
        <div class="rounded-1 placeholder bg-secondary col-6"></div>
      </div>
    </template>
    
    <template v-slot:status>
      <div class="rounded-1 placeholder bg-secondary placeholder-lg h-30px w-80px"></div>
    </template>

    <template v-slot:filters>
      <div class="d-flex flex-row gap-3">
        <div class="rounded-1 placeholder bg-secondary placeholder-lg h-30px w-30px"></div>
        <div class="rounded-1 placeholder bg-secondary placeholder-lg h-30px w-30px"></div>
      </div>
    </template>
  
    <template v-slot:actions>
      <div class="rounded-1 placeholder bg-secondary placeholder-lg h-35px w-35px"></div>
    </template>
  </Datatable>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Datatable from "@/components/kt-datatable/KTDataTable.vue";

export default defineComponent({
  name: 'placeholder-pages-search-desktop',
  components: {
    Datatable,
  },
  props: {
    length: {
      type: Number,
      required: true,
    },
    tableHeader: {
      type: Object,
      required: false,
    },
    itemsPerPage: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    const placeholderItems = Array.from({ length: props.length }, () => ({}));

    return {
      placeholderItems,
    }
  },
});
</script>