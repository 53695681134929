import { defineStore } from 'pinia';
import { decodeToken } from "@/core/services/JwtService";
import { handlerApiError } from '@/utils/helpers/storeHelpers';
import { oauthData, type IOAuthData} from "@/core/data/oauth";
import {
  notifyChannelsData, notifyTimesData,
  type MessageTG, type NotifyChannels, type NotifyTimes
} from "@/core/data/notify";
import ApiService from "@/core/services/ApiService";
import EncryptionService from '@/core/services/EncryptionService';
import { resetAllStores } from '@/stores/clearStores';
import { useOnline } from '@vueuse/core';

export const LS_USER_KEY = "kt_user";
export const SS_SEARCH_FILTERS = "searchFilters";

export interface User {
  api_token: string;
  name: string;
  last_name: string;
  email: string;
  password?: string;
  confirm_password?: string;
  mobile_phone?: string;
  ext_auth?: boolean;
  agreement_accepted?: boolean;
  is_photo_socserv?: boolean;
  subscription?: Subscription;
  city?: City;
}

export interface City {
  name?: string;
  place?: string | null;
  lat?: string | null;
  lon?: string | null;
  time_zone?: string | null;
}

export interface Subscription {
  type: string;
  level: number;
  color: string;
  maxGroups: number;
  maxSearch: number;
  maxLink: number;
  maxChartPeriod: number;
}

export interface Sessions {
  
}

export const useAuthStore = defineStore('auth', {
  state: () => {
    const storedUser = getLocalStorageItem(LS_USER_KEY) || {} as User; // Инициализация из localStorage
    return {
      errors: [] as string[],
      user: storedUser,
      isAuthenticated: !!storedUser.api_token, // Проверка наличия токена для начальной аутентификации
      oauthStore: oauthData,
      messageStoreTG: { access: true, connected: true } as MessageTG,
      notifyChannels: [ ...notifyChannelsData ] as NotifyChannels[],
      notifyTimes: [ ...notifyTimesData ] as NotifyTimes[],
      notifyPause: false,
      sessions: [] as Sessions[],
    };
  },

  getters: {
    isDemo: (state) => state.user.subscription?.type === 'Demo',
    isSubscription: (state) => (type: "group" | "search", count: number): boolean => {
      const maxCount = type === 'group' ? state.user.subscription?.maxGroups : state.user.subscription?.maxSearch;
      return count >= (maxCount ?? 1);
    },
  },

  actions: {
    setAuth(item: any) {
      this.isAuthenticated = true;
      this.user = parseDecode(item);
      this.errors = [];
      window.localStorage.setItem(LS_USER_KEY, JSON.stringify(item)); // Сохраняем данные пользователя в localStorage
      ApiService.setHeader(); // Важно! Устанавливаем токен после сохранения данных
    },
    
    purgeAuth() {
      this.isAuthenticated = false;
      this.user = {} as User;
      this.errors = [];
      window.localStorage.removeItem(LS_USER_KEY); // Удаляем данные пользователя из localStorage
      window.sessionStorage.removeItem(SS_SEARCH_FILTERS); // Удаляем данные фильтра
      ApiService.setHeader(); // Сбрасываем токен в заголовках
    },

    logout() {
      ApiService.get("pulse.user.logout", { sessid: 'true' }); // logout in bitrix
      this.purgeAuth(); // logout in palert
      resetAllStores(); // clear all stores
    },

    /**
     * Выполняет вход пользователя.
     *
     * Шифрует пароль пользователя с использованием AES-GCM и отправляет его на сервер для авторизации.
     *
     * @param {Object} credentials - Учетные данные пользователя (email и password).
     * @returns {Promise<void>} - Промис, который выполняется после попытки входа.
     */
    async login(credentials: { email: string; password: string }): Promise<void> {
      try {
        // Шифруем пароль с использованием EncryptionService
        const encryptedPassword: string = await EncryptionService.encryptPassword(credentials.password);
    
        // Подготавливаем данные для отправки на сервер
        const payload = {
          email: credentials.email,
          password: encryptedPassword, // Используем зашифрованный пароль
        };
    
        // Отправляем данные на сервер
        const authResponse = await ApiService.post('pulse.user.auth', payload);
    
        // Устанавливаем аутентификацию
        this.setAuth(authResponse.data.result);
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },

    async register(credentials: User): Promise<void> {
      return await ApiService.post("pulse.user.register", credentials)
        .then(() => {
          return; // Успешная регистрация
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async verifyEmail(credentials: { user_id: string, confirm_code: string }): Promise<void> {
      return await ApiService.post("pulse.user.verifyEmail", credentials)
        .then(({ data }) => {
          if (data.result?.api_token) {
            this.setAuth(data.result);
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },
    
    async acceptAgreement(agreement_accepted: number ): Promise<void> {
      return await ApiService.post("pulse.user.acceptAgreement", agreement_accepted)
        .then(({ data }) => {
          if (data.result?.api_token) {
            this.setAuth(data.result);
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },
    
    async forgotPassword(email: string): Promise<void> {
      return await ApiService.post("pulse.user.forgot", email)
        .then((data) => {
          return; // Успешный запрос на восстановление пароля
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async changePassword(changeData: { password: string; email: string; checkword: string }): Promise<void> {
      try {
        // Шифруем пароль с использованием EncryptionService
        const encryptedPassword: string = await EncryptionService.encryptPassword(changeData.password);
    
        // Подготавливаем данные для отправки на сервер
        const payload = {
          password: encryptedPassword, // Используем зашифрованный пароль
          email: changeData.email,
          checkword: changeData.checkword,
        };
    
        // Отправляем данные на сервер
        const data = await ApiService.post('pulse.user.password', payload);
        
        return; // Успешный запрос на изменение пароля
        
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },
    
    async changeEmailAcc(emailData: { email_address: string; confirm_email_password: string }): Promise<void> {
      try {
        // Шифруем пароль с использованием EncryptionService
        const encryptedCurrentPassword: string = await EncryptionService.encryptPassword(emailData.confirm_email_password);
    
        // Подготавливаем данные для отправки на сервер
        const payload = {
          new_email: emailData.email_address,
          current_email: this.user.email,
          current_password: encryptedCurrentPassword,
        };
        
        // Отправляем данные на сервер
        const { data } = await ApiService.post('pulse.user.acc.email', payload);

        if (data.result?.api_token) {
          this.setAuth(data.result);
        }
        
        return;

      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },
    
    async changePasswordAcc(passwordData: { current_password: string; new_password: string; confirm_password: string }): Promise<void> {
      try {
        // Шифруем пароль с использованием EncryptionService
        const encryptedCurrentPassword: string = await EncryptionService.encryptPassword(passwordData.current_password);
        const encryptedNewPassword: string = await EncryptionService.encryptPassword(passwordData.new_password);
        const encryptedConfirmPassword: string = await EncryptionService.encryptPassword(passwordData.confirm_password);
    
        // Подготавливаем данные для отправки на сервер
        const payload = {
          email: this.user.email,
          current_password: encryptedCurrentPassword,
          new_password: encryptedNewPassword,
          confirm_password: encryptedConfirmPassword,
        };
        
        // Отправляем данные на сервер
        const data = await ApiService.post('pulse.user.acc.password', payload);
        
        return; // Успешный запрос на изменение пароля
        
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },
    
    /**
     * Обновление данных пользователя через общий метод updateUserData.
     * @param credentials - Объект с данными пользователя.
     * @returns Promise<void>
     */
    async updateUser(credentials: User): Promise<void> {
      return await this.updateUserData("pulse.user.update", credentials);
    },
    
    /**
     * Обновление полей пользователя, связанных с Chaport.
     * @param params - Объект с данными для обновления.
     * @returns Promise<void>
     */
    async updateUserСhaport(params: object): Promise<void> {
      return await this.updateUserData("pulse.user.update.fields", params);
    },
    
    /**
     * Общий метод для отправки данных на обновление пользователя.
     * @param url - Эндпоинт API для обновления данных.
     * @param params - Объект с данными для обновления.
     * @returns Promise<void>
     */
    async updateUserData(url: string, params: object): Promise<void> {
      return await ApiService.post(url, params, {
        "Content-Type": "multipart/form-data",
      })
        .then(({ data }) => {
          if (data.result?.api_token) {
            this.setAuth(data.result); // Устанавливаем новые данные пользователя
          }
        })
        .catch(error => {
          handlerApiError(this.errors, error); // Обработка ошибок
        });
    },
    
    async verifyAuth(): Promise<void> {
      const isOnline = useOnline();
      const storedUser = getLocalStorageItem(LS_USER_KEY);
      if (storedUser?.api_token) {
        this.user = storedUser;
        this.isAuthenticated = true;
        
        if (!isOnline.value) {
          return;
        }

        // Делаем запрос для верификации токена
        await ApiService.post("pulse.user.verify", { api_token: storedUser.api_token })
          .then(({ data }) => {
            if (data.result?.api_token) {
              this.setAuth(data.result);
            }
          })
          .catch(error => {
            handlerApiError(this.errors, error);
          });
      } else {
        this.purgeAuth();
      }
    },

    async verifyOauthWithSession(): Promise<void> {  
      return await ApiService.get("pulse.user.oauthWithSession", { sessid: 'true' })
        .then(({ data }) => {
          if (data.result?.api_token) {
            this.setAuth(data.result);
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async oauthList(): Promise<void> {
      return await ApiService.get("pulse.user.oauth.list", { sessid: 'true', backurl: '/oauth-callback' })
        .then(({ data }) => {
          if (data.result) {
            this.oauthStore = toLowerCaseKeys(data.result);
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async oauthRemove(userOauthID: string): Promise<boolean> {
      return await ApiService.post("pulse.user.oauth.delete", { userOauthID: userOauthID })
        .then(({ data }) => {
          return !!data.result;
        })
        .catch(error => {
          handlerApiError(this.errors, error);
          return false;
        });
    },

    async oauthTG(credentials: any | null, checkKey: string, signIn: boolean): Promise<boolean> {
      return await ApiService.post(`pulse.user.oauth.telegram?check_key=${checkKey}&sessid=true`, { data: credentials, signin: signIn })
        .then(({ data }) => {
          if (data.result?.api_token) {
            this.setAuth(data.result);
          }
        })
        .catch(error => {
          handlerApiError(this.errors, error);
        });
    },

    async getSendMessageTG(): Promise<void> {
      return await ApiService.get("pulse.user.telegram.getSendMessage")
        .then(({ data }) => {
          if (data.result) {
            this.messageStoreTG = data.result;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },
    
    async fetchNotifyChannels(channels: any): Promise<NotifyChannels | undefined> {
      return await ApiService.get("pulse.user.notify.channels", channels)
        .then(({ data }) => {
          // Проверяем, что data.result существует и это массив
          if (data.result && Array.isArray(data.result) && data.result.length > 0) {
            // Преобразуем массив data.result в объект для быстрого поиска по 'type'
            const notifyChannelsMap = data.result.reduce((map, item) => {
              map[item.type] = item;
              return map;
            }, {} as Record<string, any>);
          
            // Сливаем this.notifyChannels с data.result
            const mergedData = this.notifyChannels.map(channel => {
              const matchedChannel = notifyChannelsMap[channel.type];
          
              return {
                ...channel,         // Оригинальные данные из this.notifyChannels
                ...matchedChannel,  // Данные из data.result, включая id и checked
              };
            });
            
            this.notifyChannels = mergedData;
            
            return true;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },

    async fetchNotifyTimes(times: any): Promise<NotifyTimes | undefined> {
      return await ApiService.get("pulse.user.notify.times", times)
        .then(({ data }) => {
          // Проверяем, что data.result существует и это массив
          if (data.result && Array.isArray(data.result) && data.result.length > 0) {
            
            // Установить значение checked или false, если элемент не найден
            const pauseItem = data.result.find(item => item.type === "pause");
            this.notifyPause = pauseItem ? pauseItem.checked : false; 

            // Преобразуем массив data.result в объект для быстрого поиска по 'type'
            const notifyTimesMap = data.result.reduce((map, item) => {
              map[item.type] = item;
              return map;
            }, {} as Record<string, any>);
          
            // Сливаем this.notifyTimes с data.result
            const mergedData = this.notifyTimes.map(time => {
              const matchedTime = notifyTimesMap[time.type];
          
              return {
                ...time,         // Оригинальные данные из this.notifyTimes
                ...matchedTime,  // Данные из data.result
              };
            });
            
            this.notifyTimes = mergedData;
            
            return true;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },
    
    async fetchSessions(period?: number): Promise<Sessions | undefined> {
      const lang = window.localStorage.getItem('lang');
      return await ApiService.get("pulse.user.sessions", {lang, period})
        .then(({ data }) => {
          if (data.result) {
            this.sessions = data.result;
          }
        })
        .catch(error => handlerApiError(this.errors, error));
    },
  },
});

/**
 * Извлекает и декодирует данные из localStorage по заданному ключу.
 * @param key - Ключ для получения данных из localStorage.
 * @returns Объект данных, декодированный из localStorage, или null в случае ошибки.
 */
function getLocalStorageItem(key: string): any {
  const item = window.localStorage.getItem(key);
  try {
    const parsedItem = item ? JSON.parse(item) : null;
    return parseDecode(parsedItem);
  } catch (e) {
    // console.error('Error parsing localStorage item', e);
    return null;
  }
}

/**
 * Декодирует объект пользователя, используя его JWT токен, и преобразует данные.
 * @param parsedItem - Объект данных пользователя, полученный из localStorage.
 * @returns Объект пользователя с декодированными данными и информацией о подписке.
 */
function parseDecode(parsedItem: any): any {
  if (parsedItem?.api_token) {
    const decoded = decodeToken(parsedItem.api_token);
    const transformedData = toLowerCaseKeys(decoded.data);
    const subscription: Subscription = {
      type: transformedData.type,
      level: transformedData.level,
      color: transformedData.color,
      maxGroups: transformedData.max_group,
      maxSearch: transformedData.max_search,
      maxLink: transformedData.max_link,
      maxChartPeriod: transformedData.max_chart_period,
    };

    return { ...transformedData, subscription, api_token: parsedItem.api_token };
  }
  return parsedItem;
}

/**
 * Рекурсивно преобразует все ключи объекта в нижний регистр.
 * @param obj - Объект или массив, в котором необходимо преобразовать ключи.
 * @returns Новый объект с ключами в нижнем регистре.
 */
function toLowerCaseKeys(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(toLowerCaseKeys);
  }

  return Object.keys(obj).reduce((acc, key) => {
    const lowerCaseKey = key.toLowerCase();
    acc[lowerCaseKey] = toLowerCaseKeys(obj[key]);
    return acc;
  }, {} as any);
}
