import { defineStore } from 'pinia';
import { watch } from 'vue';
import ApiService from '@/core/services/ApiService';
import { handlerApiError } from '@/utils/helpers/storeHelpers';
import { handlePullEvent } from '@/core/services/PushPullService';
import { useAuthStore } from "@/stores/auth";
import { useOnline } from '@vueuse/core';

export interface PullConfig {
  server: {
    websocket_secure: string;
    clientId?: string;
  };
  channels: {
    private: { id: string, start?: string, end?: string, type?: string };
    shared: { id: string, start?: string, end?: string, type?: string };
  };
}

export interface WebSocketMessage {
  command: string;
  text: any;
}

export const usePushPullStore = defineStore('pushPull', {
  state: () => ({
    websocket: null as WebSocket | null,
    pullConfig: null as PullConfig,
    isInitialized: false as boolean,
    pingInterval: null as NodeJS.Timeout | null,
    currentRoute: null as any,
    errors: [] as string[],
  }),

  actions: {
    async init(route: any): Promise<void> {
      const authStore = useAuthStore();
      const isOnline = useOnline();
      this.currentRoute = route;
    
      // Если пользователь уже авторизован и есть интернет, инициализируем WebSocket
      if (authStore.isAuthenticated && isOnline.value) {
        await this.initializeWebSocket();
      }
    
      // Слушаем изменения авторизации и интернет-соединения
      watch(
        () => authStore.isAuthenticated,
        async (isAuthenticated) => {
          if (isAuthenticated && !this.isInitialized && isOnline.value) {
            await this.initializeWebSocket();
          } else if (!isAuthenticated && this.isInitialized) {
            this.closeWebSocket();
          }
        }
      );
      
      watch(
        () => isOnline.value,
        async (online) => {
          if (online && authStore.isAuthenticated && !this.isInitialized) {
            await this.initializeWebSocket();
          }
        }
      );
    },
    
    async initializeWebSocket(): Promise<void> {
      const isOnline = useOnline();
    
      if (!isOnline.value) {
        return;
      }
    
      await this.fetchPullConfig();
      
      if (this.pullConfig) {
        await this.setWebSocket();
      }
    },
    
    async fetchPullConfig(): Promise<PullConfig | null> {
      try {
        const { data } = await ApiService.get('pulse.pull.config');
        this.pullConfig = data.result as PullConfig;
      } catch (error) {
        handlerApiError(this.errors, error);
      }
    },

    async setWebSocket(): Promise<void> {
      const isOnline = useOnline();
    
      // Проверка перед установкой WebSocket
      if (this.isInitialized || !this.pullConfig || !isOnline.value) {
        // console.warn('WebSocket не может быть инициализирован: нет подключения к интернету.');
        return;
      }
      
      const { websocket_secure, clientId } = this.pullConfig.server;
      const channelId = `${this.pullConfig.channels.private.id}/${this.pullConfig.channels.shared.id}`;
      let websocketUrl = `${websocket_secure}?CHANNEL_ID=${encodeURIComponent(channelId)}&format=json`;

      if (clientId) {
        websocketUrl += `&clientId=${clientId}`;
      }

      // Закрываем существующий WebSocket перед созданием нового
      this.closeWebSocket();

      this.websocket = new WebSocket(websocketUrl);

      this.websocket.onopen = () => {
        // console.log('WebSocket соединение установлено');
        this.isInitialized = true;
        this.startPing();
      };

      this.websocket.onmessage = (event) => {
        this.handleWebSocketMessage(event);
      };

      this.websocket.onerror = (error) => {
        // console.error('Ошибка WebSocket:', error);
      };

      this.websocket.onclose = async () => {
        // console.warn('WebSocket соединение закрыто');
        this.isInitialized = false;
        this.stopPing();

        const authStore = useAuthStore();

        if (authStore.isAuthenticated && isOnline.value) {
          // console.log('Переподключение через 5 секунд...');
          setTimeout(async () => {
            if (!this.isInitialized && isOnline.value) {
              await this.setWebSocket();
            }
          }, 5000);
        }
      };
    },
    
    async reconnectWebSocket(newChannel: { id: string, start: string, end: string, type: string }): Promise<void> {
      const isOnline = useOnline();
    
      if (!isOnline.value) {
        // console.warn('Нет подключения к интернету. Переподключение невозможно.');
        return;
      }
    
      // console.log('Переподключение с новым каналом...');
      this.closeWebSocket();
    
      // Обновляем текущий pullConfig в зависимости от типа канала
      if (newChannel?.id && newChannel?.type) {
        if (newChannel.type === 'shared') {
          this.pullConfig.channels.shared = { ...newChannel };
        } else if (newChannel.type === 'private') {
          this.pullConfig.channels.private = { ...newChannel };
        }
        // console.log(`Обновлённый канал (${newChannel.type}):`, newChannel);
      }
    
      // Переподключаемся с обновленным конфигом
      await this.setWebSocket();
    },

    closeWebSocket(): void {
      if (this.websocket) {
        // Удаляем обработчики событий, чтобы избежать утечек памяти
        this.websocket.onopen = null;
        this.websocket.onmessage = null;
        this.websocket.onerror = null;
        this.websocket.onclose = null;
    
        // Мягкое закрытие WebSocket, если он открыт
        if (this.websocket.readyState === WebSocket.OPEN || this.websocket.readyState === WebSocket.CONNECTING) {
          // console.log('Мягкое закрытие WebSocket...');
          this.websocket.close(1000, 'User logged out');
        }
      }
    
      // Сбрасываем состояние
      this.websocket = null;
      this.isInitialized = false;
      this.stopPing();
    },

    startPing(): void {
      if (this.pingInterval) clearInterval(this.pingInterval);
      this.pingInterval = setInterval(() => {
        if (this.websocket?.readyState === WebSocket.OPEN) {
          this.websocket.send(JSON.stringify({ type: 'ping' }));
        }
      }, 30000); // Отправка ping каждые 30 секунд
    },

    stopPing(): void {
      if (this.pingInterval) {
        clearInterval(this.pingInterval);
        this.pingInterval = null;
      }
    },

    handleWebSocketMessage(event: MessageEvent): void {
      const messages = event.data.split(/#!NGINXNME!#/).filter(msg => msg.trim() !== '');
    
      for (const message of messages) {
        const cleanedMessage = message.replace(/^#!NGINXNMS!#/, '');
        
        try {
          const data: WebSocketMessage = JSON.parse(cleanedMessage);
          // console.log('Получено сообщение из WebSocket:', data);
          
          // Проверяем, что в сообщении есть серверное время
          if (data.text?.extra?.server_time_unix) {
            const isRecent = this.isRecentMessage(data.text.extra.server_time_unix);
            if (!isRecent) {
              // console.log('Сообщение устарело и будет проигнорировано:', data.text.params.date);
              return;
            }
          }
    
          handlePullEvent(data, this.currentRoute);
        } catch (error) {
          // console.error('Ошибка парсинга JSON:', error, cleanedMessage);
        }
      }
    },
    
    isRecentMessage(serverTimeUnix: number, thresholdInMinutes: number = 5): boolean {
      const serverTimeMs = serverTimeUnix * 1000; // Преобразуем в миллисекунды
      const currentTime = Date.now();
      
      const thresholdInMs = thresholdInMinutes * 60 * 1000; // Пороговое значение в миллисекундах
      return (currentTime - serverTimeMs) <= thresholdInMs;
    },
  }
});
