<template>
  <apexchart
    class="min-h-auto w-100 ps-4 pe-6"
    ref="chartRef"
    type="area"
    :options="chart"
    :series="series"
    :height="height"
  ></apexchart>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useThemeStore } from "@/stores/theme";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import { formatDatesToLocale } from "@/utils/helpers/formatters";
import type { ApexOptions } from "apexcharts";
import type VueApexCharts from "vue3-apexcharts";

export default defineComponent({
  name: "best-price-chart",
  props: {
    data: { type: Object, required: true },
    height: { type: Number, required: true },
  },
  setup(props) {
    const { locale } = useI18n();
    const chartRef = ref<typeof VueApexCharts | null>(null);
    const chart = ref<ApexOptions>({});
    const series = ref([{ name: "", data: [] }]);
    
    const themeMode = computed(() => {
      return useThemeStore().mode;
    });
    
    const updateChartOptions = () => {
      if (!chartRef.value) return;
      if (props.data.dataObj?.price) {
        chartRef.value.updateOptions(
          chartOptions(
            props.height,
            formatDatesToLocale(props.data.dataObj.date, locale.value)
          )
        );
      }
    };
    
    const fillNulls = (data: (number | null)[]) => {
      let lastValidValue = data[0];
      return data.map(value => {
        if (value === null) {
          return lastValidValue;
        }
        lastValidValue = value;
        return value;
      });
    };
    
    const fetchChartData = () => {
      if (props.data.dataObj?.price.length > 0) {
        const objDates = formatDatesToLocale(props.data.dataObj.date, locale.value);
        const filledPrices = fillNulls(props.data.dataObj.price);
        
        series.value[0].data = filledPrices.map((price, index) => ({
          x: objDates[index], y: price,
        }));
                
        setTimeout(() => updateChartOptions, 1000); // Задержка в 100 миллисекунд
      }
    };

    onMounted(() => {
      chart.value = chartOptions(props.height);
      fetchChartData();
    });
    
    watch(() => props.data.dataObj, () => {
      fetchChartData();
    }, { deep: true });

    watch(() => locale.value, () => {
      fetchChartData();
    });
    
    watch(themeMode, () => {
      updateChartOptions();
    });
    
    return {
      chart,
      chartRef,
      series,
    };
  },
});

const chartOptions = (
  height: number,
  xaxisCategories: string[],
  yaxisMin?: number,
  yaxisMax?: number
): ApexOptions => {
  const labelColor = getCSSVariableValue("--bs-gray-500");
  const borderColor = getCSSVariableValue("--bs-border-dashed-color");
  const baseprimaryColor = getCSSVariableValue("--bs-primary");
  const lightprimaryColor = getCSSVariableValue("--bs-primary");
  const basesuccessColor = getCSSVariableValue("--bs-success");
  const lightsuccessColor = getCSSVariableValue("--bs-success");

  return {
    chart: {
      fontFamily: "inherit",
      type: "area",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
      style: {
        colors: [labelColor],
        fontSize: "12px",
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.4,
        opacityTo: 0.2,
        stops: [15, 120, 100],
      },
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 3,
      colors: [baseprimaryColor, basesuccessColor],
    },
    xaxis: {
      type: "category",
      categories: xaxisCategories,
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      tickAmount: 5,
      labels: {
        rotate: -45,
        rotateAlways: true,
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
      crosshairs: {
        position: "front",
        stroke: {
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      max: yaxisMax,
      min: yaxisMin,
      tickAmount: 4,
      labels: {
        formatter: (value) => {
          return new Intl.NumberFormat('ru-RU', {
            style: 'currency',
            currency: 'RUB',
            minimumFractionDigits: 0,
            maximumFractionDigits: 0
          }).format(value);
        },
        style: {
          colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
    },
    colors: [lightprimaryColor, lightsuccessColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeWidth: 3,
    },
  };
};
</script>
