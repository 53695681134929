export const formatDatesToLocale = (
  dates: string[],
  locale: string,
  options = { day: 'numeric', month: 'short' }
): string[] => {
  const formatter = new Intl.DateTimeFormat(locale, options);

  return dates.map(date => {
    const parsedDate = new Date(date); // JavaScript автоматически распознает формат ISO 8601
    const formattedDate = formatter.format(parsedDate);

    // Проверяем, включает ли формат только месяц
    if (!options.day && options.month) {
      return formattedDate.replace(/(^|\s)([а-яёa-z])/i, match => match.toUpperCase());
    }

    return formattedDate; // Оставляем формат без изменений
  });
};