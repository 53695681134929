<template>
    {{ showNumber ? displayedValue : '' }} {{ formattedEnding }}
</template>

<script lang="ts" setup>
import { computed } from 'vue';

const props = defineProps<{
  single: string,
  few: string,
  many: string
  number: number,
  showNumber?: boolean,
  isLocalize?: boolean,
}>();

const formattedEnding = computed(() => {
  const lastDigit = props.number % 10;
  const lastTwoDigits = props.number % 100;

  if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
    return props.many;
  }

  if (lastDigit === 1) {
    return props.single;
  } else if (lastDigit >= 2 && lastDigit <= 4) {
    return props.few;
  } else {
    return props.many;
  }
});

const displayedValue = computed(() => {
  return props.isLocalize
    ? props.number.toLocaleString('ru-RU')
    : props.number.toString();
});
</script>