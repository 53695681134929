<template>
  <div
    class="modal fade"
    id="modal_import_csv"
    tabindex="-1"
    aria-hidden="true"
  >
    <div :class="[
      'modal-dialog',
      isMobile ? 'modal-fullscreen' : 'modal-lg'
    ]">
      <div class="modal-content">
        <div class="modal-header flex-stack border-bottom-0 px-lg-10 pt-lg-10 pb-0">
          <h2>{{ $t('modal.csv.title.import') }}</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-outline ki-cross fs-1"></i>
          </div>
        </div>
        <div class="modal-body p-lg-10">
          <DropzoneUploader 
            @file-parsed="handleFileParsed" 
            ref="dropzoneUploaderRef" 
          />
                      
          <button
            ref="submitButtonRef"
            class="btn btn-primary mt-5"
            :disabled="sortedCSVData.length === 0"
            @click="handleImportCSV"
          >
            <span class="indicator-label">
              {{ $t('buttons.import') }}
            </span>
            <span class="text-nowrap indicator-progress">
              {{ $t('buttons.wait') }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          
          <Datatable
            v-if="sortedCSVData.length"
            class="table align-middle table-row-dashed mt-5"
            :header="tableHeader"
            :data="sortedCSVData"
            :items-per-page="25"
            :emptyTableText="$t('dataTable.emptyTable')"
            @on-sort="handleSort"
          >
            <template v-slot:title="{ row: item }">
              <div class="text-gray-800 fs-7">{{ item.title }}</div>
            </template>

            <template v-slot:wishprice="{ row: item }">
              <div class="text-gray-700 fs-7">
                <PriceFormatter :price="item.wishprice"/>
              </div>
            </template>

            <template v-slot:group="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.group }}</div>
            </template>

            <template v-slot:required_words="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.required_words }}</div>
            </template>

            <template v-slot:excluded_words="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.excluded_words }}</div>
            </template>

            <template v-slot:ai_score="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.ai_score }}</div>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { hideModal } from "@/core/helpers/modal";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";
import { useItemStore } from "@/stores/item";
import { disableSubmitButton, enableSubmitButton } from "@/utils/helpers/itemHelpers";
import arraySort from 'array-sort';

import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import DropzoneUploader from "@/components/files/DropzoneUploader.vue"
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addToast } from '@/utils/toastManager';

export default defineComponent({
  name: "group-modal",
  components: {
    Datatable,
    DropzoneUploader,
    PriceFormatter,
  },
  setup() {
    const { t } = useI18n();
    const dataStore = useItemStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);
    const dropzoneUploaderRef = ref(null);
    const csvData = ref<any[]>([]);
    
    const tableHeader = ref([
      {
        columnName: t('pages.items.modal.placeholder.title'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.price'),
        columnLabel: "wishprice",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.group'),
        columnLabel: "group",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.requiredWords'),
        columnLabel: "required_words",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.excludedWords'),
        columnLabel: "excluded_words",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.ai_score'),
        columnLabel: "ai_score",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
    ]);
    
    const sortState = ref({ label: null, order: 'asc' });
    
    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const sortedCSVData = computed(() => {
      const items = csvData.value.slice(); // копируем массив для сортировки

      if (sortState.value.label === 'wishprice') {
        return items.sort((a, b) => {
          const priceA = parseFloat(a.wishprice || '0');
          const priceB = parseFloat(b.wishprice || '0');
          return sortState.value.order === 'desc' ? priceB - priceA : priceA - priceB;
        });
      }
    
      if (sortState.value.label) {
        // стандартная сортировка по другим параметрам
        return arraySort(items, sortState.value.label, {
          reverse: sortState.value.order === 'desc',
        });
      }
    
      return items; // если сортировка не указана
    });

    const handleFileParsed = (data: { csvData: any[] }) => {
      csvData.value = data.csvData;
    };

    const handleImportCSV = async () => {
      try {
        disableSubmitButton(submitButtonRef);
        
        await dataStore.importSearchData({ 'data': csvData.value });

        if (!Object.keys(dataStore.errors).length) {
          handleSuccess();
        } else {
          handleError(dataStore.errors);
        }
      } catch (error) {
        handleError(error);
      } finally {
        enableSubmitButton(submitButtonRef);
      }
    };

    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.importSearch`),
        'success'
      );
      
      hideModal(document.getElementById('modal_import_csv'));
      dataStore.fetchGroupData();
      dataStore.fetchSearchPartData();
      
      setTimeout(() => {
        dropzoneUploaderRef.value?.resetDropzone(); // Вызываем сброс Dropzone
      }, 400);
    }
    
    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        dataStore.errors = [];
      });
    }
    
    return {
      tableHeader,
      sortedCSVData,
      isMobile,
      handleSort,
      handleImportCSV,
      submitButtonRef,
      handleFileParsed,
      dropzoneUploaderRef,
    };
  },
});
</script>
