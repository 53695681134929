<template>
  <div
    class="modal fade"
    id="modal_export_csv"
    tabindex="-1"
    aria-hidden="true"
  >
    <div :class="[
      'modal-dialog',
      isMobile ? 'modal-fullscreen' : 'modal-lg'
    ]">
      <div class="modal-content">
        <div class="modal-header flex-stack border-bottom-0 px-lg-10 pt-lg-10 pb-0">
          <h2>{{ $t('modal.csv.title.export') }}</h2>
          <div
            class="btn btn-sm btn-icon btn-active-color-primary"
            data-bs-dismiss="modal"
          >
            <i class="ki-outline ki-cross fs-1"></i>
          </div>
        </div>
        <div class="modal-body p-lg-10">
          <button
            ref="submitButtonRef"
            class="btn btn-primary"
            :disabled="csvData.length === 0"
            @click="handleExportCSV"
          >
            <span class="indicator-label">
              {{ $t('buttons.export') }}
            </span>
            <span class="text-nowrap indicator-progress">
              {{ $t('buttons.wait') }}
              <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
            </span>
          </button>
          
          <Datatable
            v-if="csvData.length"
            class="table align-middle table-row-dashed mt-5"
            :header="tableHeader"
            :data="csvData"
            :items-per-page="25"
            :emptyTableText="$t('dataTable.emptyTable')"
            @on-sort="handleSort"
          >
            <template v-slot:title="{ row: item }">
              <div class="text-gray-800 fs-7">{{ item.title }}</div>
            </template>

            <template v-slot:wishprice="{ row: item }">
              <div class="text-gray-700 fs-7">
                <PriceFormatter :price="item.wishprice"/>
              </div>
            </template>

            <template v-slot:group="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.group }}</div>
            </template>

            <template v-slot:required_words="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.required_words }}</div>
            </template>

            <template v-slot:excluded_words="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.excluded_words }}</div>
            </template>

            <template v-slot:ai_score="{ row: item }">
              <div class="text-gray-700 fs-7">{{ item.ai_score }}</div>
            </template>
          </Datatable>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { hideModal } from "@/core/helpers/modal";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";
import { useItemStore } from "@/stores/item";
import { disableSubmitButton, enableSubmitButton } from "@/utils/helpers/itemHelpers";
import Papa from 'papaparse';
import arraySort from 'array-sort';

import Datatable from "@/components/kt-datatable/KTDataTable.vue";
import PriceFormatter from '@/components/formatters/PriceFormatter.vue';

import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addToast } from '@/utils/toastManager';

export default defineComponent({
  name: "group-modal",
  components: {
    Datatable,
    PriceFormatter,
  },
  setup() {
    const { t } = useI18n();
    const dataStore = useItemStore();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    const tableHeader = ref([
      {
        columnName: t('pages.items.modal.placeholder.title'),
        columnLabel: "title",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.price'),
        columnLabel: "wishprice",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.group'),
        columnLabel: "group",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.requiredWords'),
        columnLabel: "required_words",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.items.modal.label.excludedWords'),
        columnLabel: "excluded_words",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
      {
        columnName: t('pages.searchId.layoutList.titleTable.ai_score'),
        columnLabel: "ai_score",
        sortEnabled: true,
        columnClass: 'text-nowrap'
      },
    ]);
    
    const sortState = ref({ label: null, order: 'asc' });
    
    const handleSort = (sort) => {
      sortState.value = sort;
    };
    
    const csvData = computed(() => {
      const items = dataStore.searchs.map(item => ({
        ...item,
        group: getGroupTitleById(item.group)
      }));
        
      if (sortState.value.label === 'wishprice') {
        return items.sort((a, b) => {
          const priceA = parseFloat(a.wishprice || '0');
          const priceB = parseFloat(b.wishprice || '0');
          return sortState.value.order === 'desc' ? priceB - priceA : priceA - priceB;
        });
      }
    
      if (sortState.value.label) {
        // стандартная сортировка по другим параметрам
        return arraySort(items, sortState.value.label, {
          reverse: sortState.value.order === 'desc',
        });
      }
    
      return items; // если сортировка не указана
    });
    
    // Функция для сопоставления ID с названием группы
    const getGroupTitleById = (id: string) => {
      const group = dataStore.groups.find(group => group.id === id);
      return group ? group.title : '';
    };
        
    // Функция для генерации имени файла с текущей датой и временем
    const generateFilename = () => {
      const now = new Date();
      const formattedDate = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}-${String(now.getDate()).padStart(2, '0')}`;
      const formattedTime = `${String(now.getHours()).padStart(2, '0')}-${String(now.getMinutes()).padStart(2, '0')}`;
      return `export-${formattedDate}/${formattedTime}.csv`;
    };

    // Функция для загрузки CSV файла
    const downloadCSV = (csvData: string, filename: string) => {
      const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    };

    // Обработчик экспорта CSV с учетом выбранных колонок
    const handleExportCSV = async () => {
      try {
        disableSubmitButton(submitButtonRef);
    
        // Извлечение только полей, указанных в `tableHeader`
        const fieldsToExport = tableHeader.value.map(header => header.columnLabel);
        
        // Подготовка данных для экспорта с учетом замены group на title
        const filteredData = csvData.value.map(item => {
          const filteredItem: Record<string, any> = {};
          fieldsToExport.forEach(field => {
            filteredItem[field] = item[field];
          });
          return filteredItem;
        });
    
        // Генерация CSV с учетом выбранных полей
        const importData = Papa.unparse(filteredData, {
          delimiter: ';',
          header: true,
          quotes: false,
        });
    
        // Скачивание CSV файла с динамическим названием
        const filename = generateFilename();
        downloadCSV(importData, filename);
        
        handleSuccess();
      } catch (error) {
        handleError(error);
      } finally {
        enableSubmitButton(submitButtonRef);
      }
    };

    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.exportSearch`),
        'success'
      );
      
      hideModal(document.getElementById('modal_export_csv'));
    }
    
    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        dataStore.errors = [];
      });
    }
            
    return {
      tableHeader,
      csvData,
      isMobile,
      handleSort,
      handleExportCSV,
      submitButtonRef,
      getGroupTitleById,
    };
  },
});
</script>
