<template>
  <div
    class="menu menu-sub menu-sub-dropdown w-75 w-sm-300px mt-2 z-index-3"
    data-kt-menu="true"
  >
    <div class="p-7">
      <div class="mb-10">
        <GroupFilter
          v-model="data.groups"
          :groups="groups"
        />
      </div>

      <div class="mb-10">
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.platforms') }}:</label>
        <div class="d-flex flex-wrap">
          <label
            v-for="platform in platforms"
            :key="platform.id"
            class="form-check form-check-sm form-check-custom form-check-solid me-5 mb-3"
          >
            <input
              class="form-check-input"
              type="checkbox"
              :value="platform.id"
              v-model="data.platforms"
            />
            <span class="form-check-label user-select-none"> {{ platform.title }} </span>
          </label>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.wishprice') }}:</label>
        <div class="d-flex flex-wrap">
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label ms-0">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="data.priceComparison"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.executed') }}</span>
            </label>
          </div>
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label ms-0">
              <input
                class="form-check-input"
                type="checkbox"
                v-model="data.purchased"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.purchased') }}</span>
            </label>
          </div>
        </div>
      </div>

      <div class="mb-6">
        <label class="form-label fw-semibold">{{ $t('pages.items.filter.status') }}:</label>
        <div class="d-flex flex-wrap">
          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label ms-0">
              <input
                class="form-check-input"
                type="checkbox"
                name="archive"
                v-model="data.status.archive"
                :disabled="data.status.any"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.archive') }}</span>
            </label>
          </div>

          <div class="form-check form-switch form-switch-sm form-check-custom form-check-solid me-5 mb-5">
            <label class="form-check-label ms-0">
              <input
                class="form-check-input"
                type="checkbox"
                name="active"
                v-model="data.status.active"
                :disabled="data.status.any"
              />
              <span class="form-check-label user-select-none">{{ $t('pages.items.filter.active') }}</span>
            </label>
          </div>
          
          <label class="form-check form-check-sm form-check-custom form-check-solid mb-5">
            <input
              class="form-check-input"
              type="checkbox"
              value="any"
              v-model="data.status.any"
            />
            <span class="form-check-label user-select-none"> {{ $t('pages.items.filter.any') }} </span>
          </label>
        </div>
      </div>

      <div class="d-flex justify-content-end">
        <button
          type="button"
          class="btn btn-sm btn-light btn-outline btn-active-light-primary"
          data-kt-menu-dismiss="true"
          @click="resetFilters"
        > {{ $t('pages.items.filter.reset') }} </button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import GroupFilter from "@/components/form/GroupFilter.vue";

export const defaultFilters = {
  groups: "0",
  platforms: [],
  status: { active: true, any: false, archive: false },
  priceComparison: false,
  purchased: false
};

// Получение начальных фильтров из sessionStorage или дефолтных значений
export const getInitialFilters = () => {
  const savedFilters = sessionStorage.getItem("searchFilters");
  if (savedFilters) {
    const route = useRoute();
    const parsedFilters = JSON.parse(savedFilters);
    const initialGroup = route.query.group ? String(route.query.group) : "0";

    // Если параметр group в URL больше "0", переопределяем groups
    const filters = {
      ...defaultFilters,
      ...parsedFilters,
      groups: Number(initialGroup) > 0 ? initialGroup : parsedFilters.groups || defaultFilters.groups
    }
    
    sessionStorage.setItem("searchFilters", JSON.stringify(filters));
    return filters;
  }
  return defaultFilters;
};

export default defineComponent({
  name: "filter-items",
  components: {
    GroupFilter,
  },
  props: {
    platforms: {
      type: Array,
      required: true
    },
    groups: {
      type: Array,
      required: true
    },
    filters: {
      type: Object,
      required: true
    },
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const route = useRoute();
    const router = useRouter();
    const data = ref({ ...props.filters });
    
    // Сохранение фильтров в sessionStorage
    const saveFiltersToSession = () => {
      sessionStorage.setItem("searchFilters", JSON.stringify(data.value));
    };

    // Функция для глубокого сброса фильтров
    const resetFilters = () => {
      data.value = { ...defaultFilters };
      emit('resetFilters');
      saveFiltersToSession();
      // Перенаправляем на /search
      router.push('/search');
    };

    // Наблюдатель для обновления data при изменении props.filters
    watch(() => props.filters, (newFilters) => {
      data.value = { ...newFilters };
    }, { deep: true });
        
    watch(data, (newData) => {
      if (JSON.stringify(newData) !== JSON.stringify(props.filters)) {
        emit('applyFilters', newData);
        saveFiltersToSession();
      }
    }, { deep: true });
    
    // Наблюдение за изменениями параметра group в query
    watch(() => route.query.group, (newGroup, oldGroup) => {
      if (newGroup !== oldGroup) {
        data.value.groups = newGroup ? String(newGroup) : "0";
      }
    }, { immediate: true });
    
    // Обновление статусов на основе логики
    watch(
      () => data.value.status.archive,
      (newVal) => {
        if (newVal) {
          data.value.status.active = false;
        } else {
          data.value.status.active = true;
        }
        saveFiltersToSession();
      }
    );
    
    watch(
      () => data.value.status.active,
      (newVal) => {
        if (newVal) {
          data.value.status.archive = false;
        } else {
          data.value.status.archive = true;          
        }
        saveFiltersToSession();
      }
    );

    watch(
      () => data.value.status.any,
      (newVal) => {
        if (newVal) {
          data.value.status.active = true;
          data.value.status.archive = false;
        }
        saveFiltersToSession();
      }
    );
  
    return {
      data,
      resetFilters
    };
  }
});
</script>
