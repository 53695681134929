<template>
  <span 
    :class="['symbol-label', computedClasses]"
    :style="backgroundStyle">
    {{ image ? '' : title[0] }}
  </span>
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { getAssetUrl } from "@/core/helpers/assets";

// Определяем пропсы
const props = defineProps<{
  title: string;
  image?: string;
  fontSizeClass: string;
}>();

// Массивы для группировки букв по цветам
const colorGroups = {
  'bg-danger text-inverse-danger': ['A', 'Я', '8', 'T', 'Щ', 'M', 'D', 'Ы', '3', 'X', 'E', 'З', '2', 'Ж'],
  'bg-success text-inverse-success': ['B', 'Ш', '9', 'N', 'В', 'J', 'Л', '7', 'H', 'Г', '4', 'F', 'Q'],
  'bg-info text-inverse-info': ['C', 'Ю', '5', 'O', 'Й', 'R', 'З', 'Х', 'K', '0', 'Е', 'Ф', 'Ч'],
  'bg-primary text-inverse-primary': ['D', 'Ц', '6', 'P', 'У', 'L', 'М', 'П', 'S', 'Й', 'А', 'И', '1'],
  'bg-warning text-inverse-warning': ['E', 'Э', '0', 'V', 'К', 'W', 'Ь', 'Н', 'Y', 'Л', 'Б', 'И', '7'],
  'bg-dark text-inverse-dark': ['F', 'Ъ', '1', 'G', 'Р', 'U', 'О', 'З', '8', 'Ц', 'П', 'Т', '5'],
  'bg-secondary text-inverse-secondary': ['G', 'Щ', '2', 'H', 'Д', 'V', 'С', 'Ф', '9', 'Ч', 'Ж', 'Р', 'Ш'],
  'bg-light text-inverse-light': ['I', 'З', '3', 'J', 'Г', 'X', 'Ь', 'Л', '4', 'М', 'Э', 'О', 'Ю'],
};

// Функция для получения цвета на основе первой буквы
const getColorClass = (char: string) => {
  const upperChar = char.toUpperCase();
  for (const [colorClass, letters] of Object.entries(colorGroups)) {
    if (letters.includes(upperChar)) {
      return colorClass;
    }
  }
  return ''; // Если буква не найдена, можно вернуть пустую строку или дефолтный класс
};

// Вычисляемые классы на основе пропсов
const computedClasses = computed(() => {
  if (props.image) {
    return props.fontSizeClass;
  }
  
  const firstChar = props.title[0];
  const colorClass = getColorClass(firstChar);
  
  return `${props.fontSizeClass} ${colorClass}`;
});

// Вычисляемый стиль для background-image
const backgroundStyle = computed(() => {
  return props.image ? `background-image: url('${getAssetUrl(props.image)}');` : '';
});
</script>
