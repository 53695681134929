<template>
  <Multiselect
    :searchable="true"
    :createTag="!isCreateGroup"
    :onCreate="onCreateGroup"
    :caret="false"
    :infinite="true"
    :limit="10"
    :placeholder="itemStore.groups?.length > 0 ? $t('pages.items.modal.placeholder.group.select') : $t('pages.items.modal.placeholder.group.add')"
    :options="transformedGroups"
    :model-value="modelValue"
    name="group"
    :noOptionsText="$t('pages.items.modal.placeholder.group.emptyList')"
    :noResultsText="$t('pages.items.modal.placeholder.group.notFound')"
    @focusin="updateIsFocused(true)"
    @focusout="updateIsFocused(false)"
    @search-change="updateTypedChars"
    @update:model-value="updateModelValue"
  >
    <template v-slot:singlelabel="{ value }">
      <div class="multiselect-single-label">
        <div class="symbol symbol-30px me-3">
          <DynamicSymbol :title="value.label" :image="value.image" fontSizeClass="fs-5" />
        </div>
        {{ value.label }}
      </div>
    </template>

    <template v-slot:option="{ option }">
      <div class="symbol symbol-30px me-3">
        <DynamicSymbol :title="option.label" :image="option.image" fontSizeClass="fs-5" />
      </div>
      {{ option.label }}
    </template>
  </Multiselect>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import { useItemStore } from "@/stores/item";
import { useAuthStore } from "@/stores/auth";
import { useI18n } from 'vue-i18n';
import { isMobile } from "@/core/helpers/mobile";
import Multiselect from '@vueform/multiselect';
import DynamicSymbol from '@/components/formatters/DynamicSymbol.vue';
import { addToast } from '@/utils/toastManager';

// Props and Emits
const props = defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
  maxLength: {
    type: Number,
    default: null,
  },
});

const emit = defineEmits({
  // Событие для обновления модели значения
  'update:modelValue': (value: string | null) => true,
  
  // Событие для уведомления о фокусе
  'update:isFocused': (value: boolean) => true,
  
  // Событие для передачи количества введённых символов
  'update:typedChars': (value: number) => true,
});

// Stores
const itemStore = useItemStore();
const userStore = useAuthStore();
const { t } = useI18n();

// States and Computed Properties
const isFocused = ref(false);

const transformedGroups = computed(() => {
  return itemStore.groups.map(item => ({
    value: item.id,
    label: item.title,
    image: item.image,
  }));
});

const isCreateGroup = computed(() => {
  // Фильтруем группы, у которых isNew не установлен или равен false
  const filteredGroups = itemStore.groups?.filter(group => !group.isNew);
  // Считаем количество таких групп
  return userStore.isSubscription('group', filteredGroups.length);
});

// Methods
const onCreateGroup = async (option, select$) => {
  // Приводим строки к нижнему регистру и проверяем, существует ли уже группа с таким названием
  const isTitleExists = itemStore.groups.some(group => group.title.toLowerCase() === option.label.toLowerCase());
  // Если группа с таким названием существует, возвращаем false
  if (isTitleExists) {
    addToast(
      t('messages.any.warning'),
      t('messages.text.warning.addGroup'),
      'warning',
      isMobile.value ? 'bottom-center' : undefined
    );
    return false;
  }
  
  // Проверяем, что название группы не состоит только из чисел
  const isTitleOnlyNumbers = /^\d+$/.test(option.label);
  if (isTitleOnlyNumbers) {
    addToast(
      t('messages.any.warning'),
      t('messages.text.warning.invalidGroupName'),
      'warning',
      isMobile.value ? 'bottom-center' : undefined
    );
    return false;
  }

  // Проверяем, что длина названия не превышает props.maxLength, если maxLength задан
  if (props.maxLength !== null && option.label.length > props.maxLength) {
    addToast(
      t('messages.any.warning'),
      t('messages.text.warning.maxLengthExceeded', { maxLength: props.maxLength }),
      'warning',
      isMobile.value ? 'bottom-center' : undefined
    );
    return false;
  }

  // Предварительное добавление новой группы, так как она не существует
  itemStore.groups.push({ id: option.value, title: option.label, isNew: true });

  return option;
};

// Emit Function for Updating Model Value
const updateModelValue = (value: string | null) => {
  emit('update:modelValue', value);
};

// Emit Function for Updating isFocused
const updateIsFocused = (value: boolean) => {
  emit('update:isFocused', value);
};

// Emit Function for Updating typedChars
const updateTypedChars = (value: string | null) => {
  const length = value ? value.length : 0;
  emit('update:typedChars', length);
}
</script>