<template>
  <div :class="widgetClasses" class="card card-flush">
    <div class="card-header pt-7 mb-8 border-0">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label fw-bold fs-3 mb-2">
          {{ $t('pages.dashboard.platforms.titleCard') }}
        </span>
        <span class="text-muted fw-semibold fs-7">
          {{ $t('pages.dashboard.platforms.subTitleCard') }}
        </span>
      </h3>
    </div>
    
    <div class="card-body d-flex justify-content-between flex-column p-0">
      <div class="mb-5 px-9">
      <template
        v-if="platforms.length > 0"
        v-for="(item, i) in platforms"
        :key="i"
      >
        <div :class="['d-flex flex-stack', { 'mb-6': i !== platforms.length - 1 }]">
          <div class="d-flex align-items-center me-2">
            <div class="symbol symbol-50px symbol-fixed me-4">
              <div class="symbol-label bg-light" :style="getBackgroundStyle(item.code)"></div>
            </div>

            <div>
              <a :href="item.url" class="fs-6 text-gray-800 text-hover-primary fw-bold" target="_blank">{{ item.title }}</a>
              <div class="fs-7 text-muted fw-semibold mt-1">{{ getFormattedUrl(item.url) }}</div>
            </div>
          </div>

          <div class="d-flex flex-column me-2">
              <span class="d-flex align-items-center justify-content-end fs-7 fw-bold text-gray-500 mb-2">
                <EndingFormatter
                  :number="item.countLink"
                  :showNumber="true"
                  :single="$t('pages.dashboard.platforms.formatter.single')"
                  :few="$t('pages.dashboard.platforms.formatter.few')"
                  :many="$t('pages.dashboard.platforms.formatter.many')"
                />
              </span>
          
              <span class="d-flex align-items-center justify-content-end text-gray-500 fw-bold fs-7">
                <EndingFormatter
                  :number="item.countItem"
                  :showNumber="true"
                  :isLocalize="true"
                  :single="$t('pages.items.formatter.single')"
                  :few="$t('pages.items.formatter.few')"
                  :many="$t('pages.items.formatter.many')"
                />
              </span>
          </div>
        </div>
      </template>
      
      <template v-else>
        <template v-for="(item, i) in placeholderPlatforms" :key="i">
          <div :class="['d-flex justify-content-between', { 'mb-6': i !== placeholderPlatforms.length - 1 }]">
            <div class="d-flex flex-row align-items-center w-75 me-2">
              <div class="symbol symbol-50px me-4">
                <span class="symbol-label placeholder bg-secondary"></span>
              </div>
              <div class="d-flex flex-column w-100">
                <div class="rounded-1 placeholder bg-secondary placeholder-lg col-7 mb-3"></div>
                <div class="rounded-1 placeholder bg-secondary placeholder-sm col-7"></div>
              </div>
            </div>
            <div class="d-flex flex-column align-self-center w-25">
              <div class="rounded-1 placeholder bg-secondary placeholder-sm col-12 mb-3"></div>
              <div class="rounded-1 placeholder bg-secondary placeholder-sm col-12"></div>
            </div>
          </div>
        </template>
      </template>
      </div>
      
      <PlatformChart
        className="card-rounded-bottom"
        :chart-color-item="chartColorItem"
        :chart-color-link="chartColorLink"
        :chart-height="chartHeight"
        :chart-data="itemStore.chartPlatforms"
      />

    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { getAssetPath } from "@/core/helpers/assets";
import { useItemStore } from "@/stores/item";
import EndingFormatter from "@/components/formatters/EndingFormatter.vue";
import PlatformChart from "@/components/chart/PlatformChart.vue";

export default defineComponent({
  name: "default-dashboard-platforms",
  components: {
    EndingFormatter,
    PlatformChart,
  },
  props: {
    widgetClasses: String,
    chartColorItem: String,
    chartColorLink: String,
    chartHeight: String,
  },
  setup(props) {
    const itemStore = useItemStore();

    onMounted( async() => {
      await Promise.all([
        itemStore.fetchPlatformData(),
        itemStore.fetchPlatformChartData()
      ]);
    });
    
    const platforms = computed(() => itemStore.platforms?.slice(0, 5));
    const placeholderPlatforms = Array.from({ length: 3 }, () => ({}));

    // Функция для получения стиля фона
    const getBackgroundStyle = (code: string) => {
      return `background-image: url('${getAssetPath(`media/svg/brand-logos/${code}.svg`)}');`;
    };
    
    // Функция для форматирования URL без протокола
    const getFormattedUrl = (url: string) => {
      return url.replace(/^https?:\/\//, '');
    };

    return {
      itemStore,
      platforms,
      placeholderPlatforms,
      getBackgroundStyle,
      getFormattedUrl,
    };
  },
});
</script>
