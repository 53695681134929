<template>
  <!--begin::User-->
  <div class="d-flex flex-column">
    <!--begin::Name-->
    <div class="d-flex align-items-center mb-2">
      <div 
        v-if="userStore.user.name || userStore.user.last_name"
        class="text-gray-800 fs-2 fw-bold me-3"
      >
        {{ userStore.user.name }} {{ userStore.user.last_name }}
      </div>
      <span
        v-if="userStore.user.subscription"
        class="badge fw-semibold fs-8 px-2 py-1"
        :class="`badge-light-${userStore.user.subscription.color}`"
      >
        {{ userStore.user.subscription.type }}
      </span>
    </div>
    <!--end::Name-->

    <!--begin::Info-->
    <div
      v-if="userStore.user?.email || userStore.user?.city?.name"
      class="d-flex flex-wrap fs-6 mb-4 gap-4"
    >
      <template v-if="userStore.user?.email">
        <span class="d-flex align-items-center text-gray-500 mb-2">
          <KTIcon icon-name="sms" icon-class="fs-4 me-1" />
          {{ userStore.user.email }}
        </span>
      </template>
      
      <template v-if="userStore.user?.city?.name">
        <span class="d-flex align-items-center text-gray-500 mb-2">
          <KTIcon icon-name="geolocation" icon-class="fs-4 me-1" />
          {{ userStore.user.city.name }}
        </span>
      </template>
    </div>
    <!--end::Info-->
  </div>
  <!--end::User-->
</template>

<script lang="ts" setup>
import { useAuthStore } from "@/stores/auth";

const userStore = useAuthStore();
</script>