<template>
  <!--begin::Notification Pause-->
  <div class="card mb-5 mb-xl-10 w-100" id="kt_profile_notify_not_disturb">
    <div class="card-header min-h-60px">
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutNotifications.notifyNotDisturb') }}
      </h3>
    </div>
    <div class="card-body p-9">
      <div class="mt-n3 text-gray-700 mb-9">
        {{ $t('pages.account.layoutNotifications.notifyNotDisturbText') }}
      </div>
      <div class="mt-n3 text-gray-700 mb-9">
        {{ $t('pages.account.layoutNotifications.notifyNotDisturbSubText') }}
      </div>
      <button
        :class="[
          'btn btn-sm',
          userStore.notifyPause ? 'btn-success' : 'btn-light btn-outline'
        ]"
        :disabled="isButtonDisabled"
        @click="tooglePause"
      >
        <i :class="['ki-outline fs-5', userStore.notifyPause ? 'ki-notification-on' : 'ki-notification-bing']"></i>
        {{ 
          userStore.notifyPause 
            ? $t('pages.account.layoutNotifications.notifyDisturbButton') 
            : $t('pages.account.layoutNotifications.notifyNotDisturbButton')
        }}
      </button>
    </div>
  </div>
  <!--end::Notification Pause-->
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "account-settings-notification-pause",
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    const isButtonDisabled = ref(false)
    
    const tooglePause = async () => {
      if (userStore.isDemo) {
        return;
      }
      
      isButtonDisabled.value = true;
      try {
        const success = await userStore.fetchNotifyTimes({ type: 'pause', action: userStore.notifyPause ? 'off' : 'on' });

        if (success) {
          const message = userStore.notifyPause ? 'messages.text.success.switchOnNoticesPause' : 'messages.text.success.switchOffNoticesPause';

          addToast(
            t('messages.any.success'),
            t(message),
            'success'
          );
        } else {
          errorSwal();
        }
      } catch (error) {
        errorSwal();
      } finally {
        isButtonDisabled.value = false;
      }
    };
    
    const errorSwal = () => {
      const message = userStore.notifyPause ? 'messages.text.success.switchOffNoticesPause' : 'messages.text.success.switchOnNoticesPause';

      Swal.fire({
        text: t(message),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    };
    
    return {
      userStore,
      tooglePause,
      isButtonDisabled,
    };
  },
});
</script>
