<template>
  <!--begin::Notify drawer-->
  <div
    id="kt_notify"
    ref="drawerRef"
    class="bg-body"
    data-kt-drawer="true"
    data-kt-drawer-name="notify"
    data-kt-drawer-activate="true"
    data-kt-drawer-overlay="true"
    data-kt-drawer-width="{default:'100%', 'sm': '400px', 'md': '500px'}"
    data-kt-drawer-direction="end"
    data-kt-drawer-toggle="#kt_notify_toggle"
    data-kt-drawer-close="#kt_notify_close"
  >
    <div
      v-if="isDrawerVisible"
      class="card shadow-none w-100 border-bottom-0"
    >
      <!--begin::Header-->
      <div class="card-header pe-5" id="kt_notify_header">
        <!--begin::Title-->
        <div class="card-title">
          <span class="fs-5 fw-bold text-gray-800">
            {{ $t('navbar.notify.notifications')}}
          </span>
        </div>
        <!--end::Title-->

        <!--begin::Card toolbar-->
        <div class="card-toolbar">
          <button
            type="button"
            class="btn btn-sm btn-icon btn-active-color-primary me-n2 shrink-0"
            id="kt_notify_close"
            aria-label="close"
          >
            <i class="ki-outline ki-cross fs-1"></i>
          </button>
        </div>
        <!--end::Card toolbar-->
      </div>
      <!--end::Header-->
      
      <!--begin::Tabs-->
      <div class="">
        <ul class="nav nav-tabs nav-line-tabs nav-stretch flex-nowrap flex-fill h-45px px-6 fw-semibold fs-6" id="notifications_tabs">
          <li class="nav-item" v-for="filter in filters" :key="filter.value">
            <button
              class="nav-link text-gray-600 text-active-primary text-hover-primary"
              :class="{ active: currentFilter === filter.value }"
              @click="setFilter(filter.value)"
            >
              {{ $t(filter.label) }}
            </button>
          </li>
        </ul>
      </div>
      <!--end::Tabs-->

      <!--begin::Body-->
      <div class="card-body card-scroll position-relative p-0 pb-5" id="kt_notify_body">
        <!--begin::Content-->
        <div
          id="kt_activities_scroll"
          class="position-relative scroll-y tab-content mt-6"
          data-kt-scroll="true"
          data-kt-scroll-height="auto"
          data-kt-scroll-wrappers="#kt_activities_body"
          data-kt-scroll-dependencies="#kt_activities_header"
        >
          <div class="tab-pane fade show active">
            <NotificationType :items="filteredItems" />
          </div>
        </div>
        <!--end::Content-->
      </div>
      <!--end::Body-->
      
      <!--begin::Footer-->
      <div
        v-if="filteredItems.length > 0"
        class="card-footer d-flex gap-3 p-5"
      >
        <button
          ref="submitButtonRef"
          class="btn btn-sm btn-outline btn-bg-light btn-active-dark fw-normal w-100"
          @click="handleClear(currentFilter)"
          :disabled="filteredItems.length === 0"
        >
          {{ $t('actions.archive') }} 
          <span class="text-lowercase">
            {{ $t('navbar.notify.notifications') }}
          </span>
        </button>
      </div>
      <!--end::Footer-->
    </div>
  </div>
  <!--end::Notify drawer-->
</template>

<script lang="ts">
import { defineComponent, onMounted, nextTick, computed, ref } from "vue";
import { useNotifyStore } from '@/stores/notify';
import { useDrawer } from "@/composables/useDrawer";
import { useI18n } from "vue-i18n";
import { disableSubmitButton, enableSubmitButton } from "@/utils/helpers/itemHelpers";

import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { addToast } from '@/utils/toastManager';

import NotificationType from '@/components/notify/NotificationType.vue';

export default defineComponent({
  name: "kt-notify-drawer",
  components: {
    NotificationType,
  },
  setup() {
    const { t } = useI18n();
    const notifyStore = useNotifyStore();
    const { drawerRef, isDrawerVisible } = useDrawer();
    const submitButtonRef = ref<null | HTMLButtonElement>(null);

    // Массив с описанием фильтров
    const filters = [
      { label: 'navbar.notify.tabs.all', value: 'all' },
      { label: 'navbar.notify.tabs.items', value: 'items' },
      // { label: 'navbar.notify.tabs.problems', value: 'problems' },
      { label: 'navbar.notify.tabs.information', value: 'information' },
    ];
    
    // Состояние для фильтра
    const currentFilter = ref("all");

    // Функция для изменения фильтра
    const setFilter = (filter) => {
      currentFilter.value = filter;
    };
    
    const filterFunctions = {
      all: (items) => items,
      items: (items) => items.filter((item) => item.type === "price_drop"),
      // problems: (items) => items.filter((item) => item.type === "link_disabled"),
      information: (items) => items.filter((item) => item.type !== "price_drop"),
    };

    const sortedItems = computed(() => {
      return notifyStore.items.slice().sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        if (dateA === dateB) {
          return b.id - a.id;
        }
        return dateB - dateA;
      }) || [];
    });
        
    const filteredItems = computed(() => {
      return filterFunctions[currentFilter.value]?.(sortedItems.value) || [];
    });

    const handleClear = async (type) => {
      try {
        disableSubmitButton(submitButtonRef);
        
        await notifyStore.clearNotify({ type });
    
        if (!Object.keys(notifyStore.errors).length) {
          handleSuccess();
        } else {
          handleError(notifyStore.errors);
        }
      } catch (error) {
        handleError(error);
      } finally {
        enableSubmitButton(submitButtonRef);
      }
    };
    
    // Константа для обработки успешного завершения
    const handleSuccess = () => {
      addToast(
        t('messages.any.success'),
        t(`messages.text.success.clearNotify`),
        'success'
      );
      
      // Получение массива ID из filteredItems.value
      const filteredIds = filteredItems.value.map(item => item.id);
      
      // Фильтрация notifyStore.items, оставляя только те, у которых ID нет в filteredIds
      notifyStore.items = notifyStore.items.filter(item => !filteredIds.includes(item.id));
      
      // Получаем новый список уведомлений
      notifyStore.fetchData({}, true);
    }
    
    // Константа для обработки ошибок
    const handleError = (error) => {
      Swal.fire({
        text: error,
        icon: "error",
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: "btn btn-danger",
        },
      }).then(() => {
        notifyStore.errors = [];
      });
    }

    function isSameDate(date1: string | Date, date2: string | Date): boolean {
      const d1 = new Date(date1).toDateString();
      const d2 = new Date(date2).toDateString();
      return d1 === d2;
    }
    
    onMounted(() => {
      nextTick(() => {
        notifyStore.fetchData();
      });
    });

    return {
      filters,
      drawerRef,
      isDrawerVisible,
      isSameDate,
      currentFilter,
      setFilter,
      filteredItems,
      handleClear,
      submitButtonRef,
    };
  },
});
</script>
