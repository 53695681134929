<template>
  <span class="text-nowrap">{{ formattedDate }}</span>
</template>

<script lang="ts" setup>
import { computed, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

import 'dayjs/locale/ru';
import 'dayjs/locale/en';
import 'dayjs/locale/de';
import 'dayjs/locale/zh-cn';

dayjs.extend(relativeTime);

interface Props {
  date: string | number | Date; // Дата для отображения
  format: string; // Формат даты ('X', 'x', 'D MMMM YYYY в H:mm', 'custom')
  formatType?: 'short' | 'full'; // Тип формата: сокращенный или полный
}

const props = defineProps<Props>();
const { t, locale } = useI18n(); // Получаем текущую локаль

// Локализация строк для разных языков
const locales = {
  ru: {
    code: 'ru',
    dayjsLocale: 'ru',
    atString: 'в',
    formats: {
      dateShort: 'D MMM',
      dateFull: 'D MMMM',
      dateSimple: 'DD MMM YYYY',
      dateWeekday: 'dd, D MMM',
      time: 'HH:mm',
      atTime: 'HH [ч.] mm [мин.]',
      relativeTime: true,
    },
    labels: {
      tomorrow: 'Завтра',
      today: 'Сегодня',
      yesterday: 'Вчера',
    },
  },
  en: {
    code: 'en',
    dayjsLocale: 'en',
    atString: 'at',
    formats: {
      dateShort: 'MMM D',
      dateFull: 'MMMM D',
      dateSimple: 'DD/MM/YYYY',
      dateWeekday: 'ddd, D MMM',
      time: 'h:mm A',
      relativeTime: true,
    },
    labels: {
      tomorrow: 'Tomorrow',
      today: 'Today',
      yesterday: 'Yesterday',
    },
  },
  de: {
    code: 'de',
    dayjsLocale: 'de',
    atString: 'um',
    formats: {
      dateShort: 'DD.MM.',
      dateFull: 'D. MMMM',
      dateSimple: 'DD.MM.YYYY',
      dateWeekday: 'ddd, D MMM',
      time: 'HH:mm',
      relativeTime: true,
    },
    labels: {
      tomorrow: 'Morgen',
      today: 'Heute',
      yesterday: 'Gestern',
    },
  },
  cn: {
    code: 'zh-cn',
    dayjsLocale: 'zh-cn',
    atString: '',
    formats: {
      dateShort: 'M月D日',
      dateFull: 'YYYY年M月D日',
      dateSimple: 'YYYY/MM/DD',
      dateWeekday: 'M月D日 ddd',
      time: 'HH:mm',
      relativeTime: true,
    },
    labels: {
      tomorrow: '明天',
      today: '今天',
      yesterday: '昨天',
    },
  },
  default: {
    code: 'default',
    dayjsLocale: 'en',
    atString: 'at',
    formats: {
      dateShort: 'YYYY-MM-DD',
      dateFull: 'YYYY-MM-DD',
      dateSimple: 'YYYY-MM-DD',
      dateWeekday: 'ddd, YYYY-MM-DD',
      time: 'HH:mm',
      relativeTime: false,
    },
    labels: {
      tomorrow: 'Tomorrow',
      today: 'Today',
      yesterday: 'Yesterday',
    },
  },
};

// Установка локали dayjs при изменении локали
watchEffect(() => {
  const currentLocale = locales[locale.value] || locales.default;
  dayjs.locale(currentLocale.dayjsLocale);
});

// Вычисляем форматированную дату
const formattedDate = computed(() => {
  const dateObj = dayjs(props.date);
  const nowObj = dayjs();

/*   if (!dateObj.isValid()) {
    return dayjs.localeData().invalidDate(); // Используем стандартное сообщение для невалидной даты
  } */

  if (props.format === 'dynamicDateTime') {
    return getDynamicFormat(dateObj, nowObj, { useTodayLabels: false, includeTime: true });
  }
  
  if (props.format === 'dynamicDate') {
    return getDynamicFormat(dateObj, nowObj, { useTodayLabels: false, includeTime: false });
  }
  
  if (props.format === 'dynamicToDateTime') {
    return getDynamicFormat(dateObj, nowObj, { useTodayLabels: true, includeTime: true });
  }
  
  if (props.format === 'dynamicToDate') {
    return getDynamicFormat(dateObj, nowObj, { useTodayLabels: true, includeTime: false });
  }
  
  if (props.format === 'simpleDate') {
    const currentLocale = locales[locale.value] || locales.default;
    return dateObj.format(currentLocale.formats.dateSimple); // Применяем формат simpleDate
  }

  if (props.format === 'atTime') {
    const localizedFormat = getAtTimeFormat(); // Получаем формат времени для текущей локали
    return dateObj.format(localizedFormat);
  }
  
  if (props.format === 'weekdayDate') {
    const currentLocale = locales[locale.value] || locales.default;
    return dateObj.format(currentLocale.formats.dateWeekday); // Применяем формат dateWeekday
  }

  return dateObj.format(props.format); // Стандартное форматирование
});

/**
 * Получение строки для текущей локали.
 */
function getLocalizedString(key: string): string {
  const currentLocale = locales[locale.value] || locales.default;
  return currentLocale[key] || '';
}

/**
 * Возвращает дату в формате "сегодня", "вчера", "2 дня назад" или стандартном формате.
 */
function getDynamicFormat(
  dateObj: dayjs.Dayjs,
  nowObj: dayjs.Dayjs,
  options: { useTodayLabels: boolean;includeTime: boolean }
): string {
  const { useTodayLabels, includeTime } = options;
  const currentLocale = locales[locale.value] || locales.default;
  const formats = currentLocale.formats;
  const labels = currentLocale.labels;
  const timeFormat = formats.time;
  const dateFormat = dateObj.year() === nowObj.year() ? formats.dateShort : `${formats.dateFull} YYYY`;

  // "Сегодня", "Вчера" и "Завтра"
  if (useTodayLabels) {
    if (dateObj.isSame(nowObj, 'day')) {
      return includeTime
        ? `${labels.today} ${currentLocale.atString} ${dateObj.format(timeFormat)}`
        : labels.today;
    }
    if (dateObj.isSame(nowObj.subtract(1, 'day'), 'day')) {
      return includeTime
        ? `${labels.yesterday} ${currentLocale.atString} ${dateObj.format(timeFormat)}`
        : labels.yesterday;
    }
    if (dateObj.isSame(nowObj.add(1, 'day'), 'day')) {
      return includeTime
        ? `${labels.tomorrow} ${currentLocale.atString} ${dateObj.format(timeFormat)}`
        : labels.tomorrow;
    }
  }

  // "2 дня назад" и подобное (до 5 дней)
  if (formats.relativeTime && nowObj.diff(dateObj, 'day') < 5) {
    return includeTime ?
      `${dateObj.fromNow()} ${currentLocale.atString} ${dateObj.format(timeFormat)}` :
      dateObj.fromNow();
  }

  // Стандартный формат
  return includeTime ?
    `${dateObj.format(dateFormat)} ${currentLocale.atString} ${dateObj.format(timeFormat)}` :
    dateObj.format(dateFormat);
}

/**
 * Функция для получения формата времени в зависимости от локали.
 */
function getAtTimeFormat(): string {
  const currentLocale = locales[locale.value] || locales.default;
  // Если atString пусто, возвращаем только формат времени
  return currentLocale.atString 
    ? `[${currentLocale.atString}] ${currentLocale.formats.atTime || currentLocale.formats.time}`
    : currentLocale.formats.atTime || currentLocale.formats.time;
}
</script>