<template>
  <div class="mt-2 text-gray-700">
    <div
      v-if="platform && hasUpdateDate"
      :class="{'d-flex align-items-center': !isMobile, 'text-nowrap': true}"
    >
      <KTIcon icon-name="arrows-circle" icon-class="fs-5 mx-2" />
      <DateFormatter
        :date="earliestDate"
        :format="'HH:mm'"
        class="text-gray-500 fs-7"
      />
    </div>
    
    <div
      v-else-if="itemStatus === 0"
      :class="{'d-flex align-items-center': !isMobile}"
    >
      <KTIcon icon-name="information-3" icon-class="fs-5 mx-2" />
    </div>
    
    <div
      v-else 
      :class="{'d-flex align-items-center': !isMobile}"
    >
      <KTIcon icon-name="time" icon-class="fs-5 mx-2" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';
import { isMobile } from "@/core/helpers/mobile";
import DateFormatter from '@/components/formatters/DateFormatter.vue';

export default defineComponent({
  name: 'platform-update-date',
  components: {
    DateFormatter,
  },
  props: {
    platform: {
      type: Object,
      required: true,
    },
    itemStatus: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const hasUpdateDate = computed(() => 
      props.platform.links_date_update && Object.keys(props.platform.links_date_update).some(key => key.length > 0)
    );
  
    const earliestDate = computed(() => {
      if (!hasUpdateDate.value) return '';
  
      const dates = Object.values(props.platform.links_date_update).map(date => new Date(date));
      return new Date(Math.min(...dates)).toISOString();
    });
  
    return {
      hasUpdateDate,
      earliestDate,
      isMobile,
    };
  }
});
</script>
