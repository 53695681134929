<template>
  <!--begin::Wrapper-->
  <div
    v-if="chartData" 
    class="d-flex flex-stack flex-row-fluid"
  >
    <!--begin::Block-->
    <div class="d-flex flex-column mb-6">
      <div class="d-flex align-items-center mb-1">
        <span :class="[
          'fs-2hx fw-bold me-3 text-nowrap',
          typeReal ? 'text-white' : 'text-gray-800'
        ]">
          <SmoothCounter
            :targetValue="stat.savings"
            suffix=" ₽"
            :isLocalize="true"
          />
        </span>
        
        <template v-if="stat">
          <span v-if="!stat.savings">
            <KTIcon icon-name="arrow-mix" icon-class="fs-7 text-success align-middle" />
          </span>
          <span v-else-if="stat.up">
            <KTIcon icon-name="arrow-up" icon-class="fs-7 text-success align-middle" />
          </span>
          <span v-else>
            <KTIcon icon-name="arrow-down" icon-class="fs-7 text-danger align-middle" />
          </span>
        </template>
        
        <span
          :class="[
            'fw-semibold fs-6 ms-1',
            stat.up ? 'text-success' : 'text-danger'
          ]">
          <SmoothCounter
            :targetValue="parseFloat(stat.num)"
            suffix="%"
            :isRound="true"
          />
        </span>
      </div>
      
      <template v-if="typeReal">
        <span class="fs-6 fw-semibold text-white opacity-50">
          <EndingFormatter
            :number="chartData.count ?? 0"
            :showNumber="true"
            :single="$t('widgets.dashboard.savings.real.formatter.single')"
            :few="$t('widgets.dashboard.savings.real.formatter.few')"
            :many="$t('widgets.dashboard.savings.real.formatter.many')"
          />
        </span>
      </template>
      <template v-else>
        <div
          v-if="potentialDate"
          class="text-gray-500 fw-semibold"
        >
          {{ $t('widgets.dashboard.savings.potential.information') }}
          <DateFormatter 
            :date="potentialDate"
            :format="'dynamicToDate'"
            class="text-lowercase"
          />
        </div>
        <div
          v-else
          class="text-gray-500 fw-semibold"
        >
          {{ $t('widgets.dashboard.savings.potential.noInformation') }}
        </div>
      </template>
    </div>
    <!--end::Block-->

    <!--begin::Chart-->
    <apexchart
      ref="chartRef"
      class="h-75px h-sm-100px w-100px w-sm-125px"
      :options="chart"
      :series="series"
      type="area"
    ></apexchart>
    <!--end::Chart-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import type { ApexOptions } from "apexcharts";
import type VueApexCharts from "vue3-apexcharts";
import { useThemeStore } from "@/stores/theme";
import { useItemStore } from "@/stores/item";

import DateFormatter from '@/components/formatters/DateFormatter.vue';
import SmoothCounter from '@/components/formatters/SmoothCounter.vue'
import EndingFormatter from '@/components/formatters/EndingFormatter.vue'

export default defineComponent({
  name: "chart-savings",
  components: {
    DateFormatter,
    SmoothCounter,
    EndingFormatter,
  },
  props: {
    typeChart: {
      type: String,
      required: true
    },
  },
  setup(props) {
    const chartRef = ref<typeof VueApexCharts | null>(null);
    const chart = ref<ApexOptions>({});
    const series = ref([{ name: "", data: [] }]);
    const itemStore = useItemStore();
    
    const initialStat = { up: true, num: 0.00, savings: 0 };
    const stat = ref({ ...initialStat });
    const chartData = ref({});
    
    const themeMode = computed(() => useThemeStore().mode);
    const typeReal = computed(() => props.typeChart === 'real');
    const chartColor = computed(() => typeReal.value ? '--bs-white' : '--bs-success');

    // Сброс значений stat перед каждым обновлением данных
    const resetStat = () => {
      stat.value = { ...initialStat };
    };
    
    const potentialDate = computed(() => {
      const dates = chartData.value.date;
      if (!dates || dates.length === 0) {
        return 0; // или любое значение по умолчанию
      }
      return dates[dates.length - 1]; // последнее значение
    });
    
    const potentialPercentageStat = () => {
      const prices = chartData.value?.price;
      if (!prices || prices.length < 2) {
        return 0; // или любое значение по умолчанию, если недостаточно данных
      }
      
      const first = prices[0];
      const last = prices[prices.length - 1];
      
      // Рассчитываем процентную разницу
      if (first === 0) {
        return 0; // во избежание деления на ноль
      }
      
      // Вычисляем разницу в процентах между первым и последним значением
      stat.value.num = ((Math.abs(first - last) / first) * 100).toFixed(2);
      stat.value.up = first > last ? false : true;
    };
        
    const realPercentageStat = () => {
      const percentages = chartData.value?.percentage;
      if (!percentages || !Array.isArray(percentages) || percentages.length === 0) {
        return 0; // Если данных нет или массив пустой, возвращаем 0
      }
    
      // Суммируем значения и вычисляем среднее
      stat.value.num = Math.abs(
        chartData.value.percentage.reduce((acc, curr) => acc + curr, 0) / chartData.value.percentage.length
      );
      stat.value.up = stat.value.num > 0 ? true : false;
    };
    
    const savingsMath = () => {
      const prices = chartData.value?.price;
      if (!prices || !Array.isArray(prices) || prices.length === 0) {
        return 0; // или любое значение по умолчанию
      }
      
      stat.value.savings = typeReal.value 
        ? prices.reduce((acc, curr) => acc + curr, 0)
        : prices[prices.length - 1];
    };

    const fillNulls = (data: (number | null)[]) => {
      let lastValidValue = data[0];
      return data.map(value => {
        if (value === null) {
          return lastValidValue;
        }
        lastValidValue = value;
        return value;
      });
    };

    const fetchChartData = async () => {
      resetStat(); // Сбрасываем stat перед загрузкой новых данных
      savingsMath();
      
      typeReal.value ? realPercentageStat() : potentialPercentageStat();      
    
      if (chartData.value && chartData.value.price?.length > 0) {
        const filledPrices = fillNulls(chartData.value.price);
        series.value[0].data = filledPrices.map((price, index) => ({
          x: chartData.value.date[index],
          y: price,
        }));
        
        // chart.value = chartOptions(chartColor.value);
      }
    };
    
    const refreshChart = () => {
      if (!chartRef.value) {
        return;
      }
      
      if (chartData.value && chartData.value.price) {
        chartRef.value.updateOptions(
          chartOptions(chartColor.value)
        );
      }
    };
    
    onMounted( async() => {
      chart.value = chartOptions(chartColor.value);

      if (typeReal.value) {
        await itemStore.fetchRealChartData();
        chartData.value = itemStore.chartRealSavings;
      } else {
        await itemStore.fetchPotentialChartData();
        chartData.value = itemStore.chartPotentialSavings;
      }

      fetchChartData();
    });
    
    watch(themeMode, () => {
      refreshChart();
    });

    return {
      chart,
      series,
      chartRef,
      chartData,
      stat,
      typeReal,
      potentialDate
    };
  }
});

const chartOptions = (
  chartColor: string = "--bs-white"
): ApexOptions => {
  const baseColor = getCSSVariableValue(chartColor);
  const lightColor = getCSSVariableValue(chartColor);

  return {
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 'auto',
      toolbar: {
          show: false
      },
      zoom: {
          enabled: false
      },
      sparkline: {
          enabled: true
      },
    },
    legend: {
      show: false
    },
    dataLabels: {
      enabled: false
    },
    fill: {
      type: "gradient",
      gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.4,
          opacityTo: 0,
          stops: [0, 80, 100]
      }
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 2,
      colors: [baseColor]
    },
    xaxis: {                 
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false
      },
      labels: {
        show: false
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3
        }
      },
      tooltip: {
        enabled: false,
      }
    },
    yaxis: {
      labels: {
        show: false
      }
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      hover: {
        filter: {
          type: 'none',
          value: 0
        }
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0
        }
      }
    },
    tooltip: {
      enabled: false                
    },
    colors: [lightColor],
    grid: { 
      yaxis: {
        lines: {
          show: false
        }
      }
    },
    markers: {
      strokeColor: baseColor,
      strokeWidth: 2
    }
  }; 
};
</script>
