<template>
  <div class="d-flex align-items-center grow gap-3 mx-6">
    <div class="symbol symbol-30px">
      <div
        v-if="icon && color"
        :class="`symbol-label border border-${color}-clarity bg-light-${color}`"
      >
        <KTIcon
          :icon-name="icon"
          :icon-class="`fs-2 bg-light-${color} text-${color}`"
        />
      </div>
      <router-link v-else-if="link" :to="link">
        <span
          v-if="backgroundStyle || notify.title"
          class="symbol-label" 
          :style="backgroundStyle"
        >
          {{ backgroundStyle ? '' : notify.title[0] }}
        </span>
        <div v-else class="symbol-label">
          <i class="ki-outline ki-ghost fs-2"></i>
        </div>
      </router-link>
    </div>
    
    <div class="d-flex flex-column text-truncate">
      <span
        v-if="message"
        class="fs-6 fw-normal text-gray-800 text-truncate"
      >
        {{ $t(`navbar.notify.alert.${message ?? 'unknown'}`, { platform: notify.platform?.title }) }}
      </span>
      <router-link
        v-else-if="link && notify.title"
        :to="link"
        :class="[
          'fs-6 fw-normal text-hover-primary text-truncate',
          notify.image ? 'text-gray-800' : 'text-gray-600'
        ]"
      >
        {{ notify.title }}
      </router-link>
      <div v-else class="fs-8 text-gray-500">
        {{ $t('navbar.notify.title.searchRemoved') }}
      </div>
      
      <div class="d-flex align-items-center">
        <DateFormatter
          v-if="notify.date"
          :date="notify.date"
          :format="'dynamicDate'"
          class="fs-8 fw-normal text-gray-500 text-nowrap"
        />
        <template v-if="notify.platform && !icon">
          <span
            v-if="notify.date"
            class="bullet bullet-dot mx-2"
          ></span>
          <a
            :href="notify.platform.link"
            class="d-flex align-items-center fs-8 text-gray-500 text-hover-primary"
            target="_blank"
          >
            {{ notify.platform.title }}
          </a>
        </template>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from "vue";
import { getAssetUrl } from "@/core/helpers/assets";

import DateFormatter from '@/components/formatters/DateFormatter.vue';

export default defineComponent({
  name: "notification-header",
  components: {
    DateFormatter,
  },
  props: {
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: null,
    },
    message: {
      type: String,
      default: null,
    },
    notify: {
      type: Object,
      default: () => ({
        data: {},
        platform: {},
      }),
    },
  },
  setup(props) {
    const message = computed(() => 
      props.message ?? props.notify.data?.message
    );
    
    const link = computed(() =>
      props.notify.searchId ? `/search/${props.notify.searchId}/items` : null
    );
    
    // Вычисляемый стиль для background-image
    const backgroundStyle = computed(() =>
      props.notify.image ? `background-image: url('${getAssetUrl(props.notify.image)}');` : null
    );

    return {
      message,
      link,
      backgroundStyle,
    };
  },
});
</script>
