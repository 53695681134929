<template>
  <!--begin::Notification Times-->
  <div :class="['card mb-5 mb-xl-10', { 'overlay overlay-block' : isLoading }]" id="notification_times">
    <!--begin::Card header-->
    <div
      class="card-header collapsible cursor-pointer rotate min-h-60px"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_notification_times"
      aria-expanded="true"
      aria-controls="kt_account_notification_times"
    >
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutNotifications.notifyTimes') }}
      </h3>
      <div class="card-toolbar rotate-180">
        <i class="ki-duotone ki-down fs-1"></i>
      </div>
    </div>
    <!--end::Card header-->
  
    <!--begin::Content-->
    <div id="kt_account_notification_times" :class="['collapse show', { 'overlay-wrapper': isLoading }]">
      <!--begin::Card body-->
      <div class="card-body p-9">
        <!--begin::Items Loop-->
        <div v-for="(notify, index) in userStore.notifyTimes" :key="index">
          
          <div class="d-flex flex-stack">
            <div class="d-flex align-items-center">
              <div class="symbol me-6">
                <svg class="stroke-gray-300 fill-gray-100" fill="none" height="48" viewbox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
                  18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
                  39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
                  </path>
                  <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
                  18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
                  39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
                  </path>
                </svg>
                <div class="symbol-badge badge top-50 start-50">
                  <i :class="`ki-outline ki-${notify.icon} fs-1 text-gray-500`"></i>
                </div>
              </div>
              <div class="d-flex flex-column me-5">
                <div class="fs-5 text-gray-900 fw-bold">
                  {{ $t(`pages.account.layoutNotifications.times.${notify.type}`) }}
                </div>
                <div class="fs-6 fw-semibold text-gray-500">
                  {{ $t(`pages.account.layoutNotifications.subTimes.${notify.type}`) }}
                </div>
              </div>
            </div>
            
            <div class="d-flex justify-content-end">
              <div v-if="isLoading"></div>
              <!--begin::Checkbox-->
              <div v-else class="form-check form-switch">
                <input
                  class="form-check-input w-40px h-25px"
                  type="checkbox"
                  :checked="notify.checked"
                  :disabled="userStore.notifyPause"
                  @change="(event) => toogleTimes(event.target.checked, notify, event)"
                />
              </div>
              <!--end::Checkbox-->
            </div>
          </div>
          
          <div v-if="index < userStore.notifyTimes.length - 1" class="separator my-5"></div>
        </div>
        <!--end::Items Loop-->          
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
    
    <div v-if="isLoading" class="overlay-layer card-rounded">
      <div class="spinner-border text-primary" role="status"></div>
    </div>
  </div>
  <!--end::Notification Times-->
</template>

<script lang="ts">
import { defineComponent, onMounted, computed } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

export default defineComponent({
  name: "account-settings-notification-times",
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    
    const isLoading = computed(() => !userStore.notifyTimes[0]?.id );

    const toogleTimes = async (isChecked: boolean, notify: any, event: Event) => {
      // Сохранение текущего состояния чекбокса
      const originalChecked = !isChecked;
      
      if (userStore.isDemo) {
        setTimeout(() => {
          revertCheckboxState(event, originalChecked);
        }, 3000); // Задержка в 3 секунды
        return;
      }
    
      if (!isChecked) {
        await handleSwitch('off', notify, event, originalChecked);
      } else if (isChecked) {
        await handleSwitch('on', notify, event, originalChecked);
      }
    };
    
    const errorSwal = () => {
      Swal.fire({
        text: t('messages.text.error.switchNoticesTime'),
        icon: 'error',
        buttonsStyling: false,
        confirmButtonText: t('messages.button.okGotIt'),
        heightAuto: false,
        customClass: {
          confirmButton: 'btn btn-danger',
        },
      });
    };
    
    // Вспомогательная функция для переключения на включение
    async function handleSwitch(action: 'on' | 'off', notify: any, event: Event, originalChecked: boolean) {
      event.target.disabled = true;
      try {
        const success = await userStore.fetchNotifyTimes({ type: notify.type, action: action });
        if (success) {
          addToast(
            t('messages.any.success'),
            t('messages.text.success.switchNoticesTime'),
            'success'
          );
        } else {
          handleError(event, originalChecked);
        }
      } catch (error) {
        handleError(event, originalChecked);
      } finally {
        event.target.disabled = false;
      }
    }
    
    // Вспомогательная функция для обработки ошибок
    function handleError(event: Event, originalChecked: boolean) {
      errorSwal();
      revertCheckboxState(event, originalChecked);
    }
    
    // Вспомогательная функция для возврата состояния чекбокса
    function revertCheckboxState(event: Event, originalChecked: boolean) {
      (event.target as HTMLInputElement).checked = originalChecked;
    }

    const fetchNotifyData = async () => {      
      await userStore.fetchNotifyTimes();
    };

    onMounted(async () => {
      await fetchNotifyData();
    });

    return {
      userStore,
      isLoading,
      toogleTimes,
    };
  },
});
</script>
