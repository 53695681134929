<template>
  <!--begin::Sign-in Method-->
  <div 
    v-if="!userStore.user.ext_auth"
    class="card mb-5 mb-xl-10"
  >
    <!--begin::Card header-->
    <div
      class="card-header collapsible cursor-pointer rotate min-h-60px"
      role="button"
      data-bs-toggle="collapse"
      data-bs-target="#kt_account_signin_method"
    >
      <h3 class="card-title fw-bold m-0">
        {{ $t('pages.account.layoutSettings.signInMethod') }}
      </h3>
      <div class="card-toolbar rotate-180">
        <i class="ki-duotone ki-down fs-1"></i>
      </div>
    </div>
    <!--end::Card header-->

    <!--begin::Content-->
    <div id="kt_account_signin_method" class="collapse show">
      <!--begin::Card body-->
      <div class="card-body p-9">
        <!--begin::Email Address-->
        <div class="d-flex flex-wrap align-items-center mb-5">
          
          <div :class="['symbol me-6', { 'd-none': emailFormDisplay }]">
            <svg class="stroke-gray-300 fill-gray-100" fill="none" height="48" viewbox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
              18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
              39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
              </path>
              <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
              18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
              39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
              </path>
            </svg>
            <div class="symbol-badge badge top-50 start-50">
              <i class="ki-outline ki-sms fs-1 text-gray-500"></i>
            </div>
          </div>
          
          <div id="kt_signin_email" :class="['d-flex flex-column me-5', { 'd-none': emailFormDisplay }]">
            <div class="fs-5 text-gray-900 fw-bold">{{ $t('pages.authentication.email') }}</div>
            <div class="fs-6 fw-semibold text-gray-500">{{ userStore.user.email }}</div>
          </div>

          <div
            id="kt_signin_email_edit"
            :class="{ 'd-none': !emailFormDisplay }"
            class="flex-row-fluid"
          >
            <!--begin::Form-->
            <VForm
              id="kt_signin_change_email"
              class="form"
              novalidate
              @submit="onSubmitChangeEmail"
              :validation-schema="changeEmailSchema"
            >
              <div class="row mb-6">
                <div class="col-lg-6 mb-4 mb-lg-0">
                  <div class="fv-row mb-0">
                    <label
                      for="email_address"
                      class="form-label fs-6 fw-bold mb-3"
                    >
                      {{ $t('pages.account.layoutSettings.newEmail') }}
                    </label>
                    <Field
                      type="email"
                      class="form-control form-control-lg form-control-solid fw-semibold fs-6"
                      id="email_address"
                      :placeholder="$t('pages.authentication.email')"
                      name="email_address"
                      autocomplete="off"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="email_address" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="fv-row mb-0">
                    <label
                      for="confirm_email_password"
                      class="form-label fs-6 fw-bold mb-3"
                    >
                      {{ $t('pages.authentication.password') }}
                    </label>
                    <Field
                      type="password"
                      class="form-control form-control-lg form-control-solid fw-semibold fs-6"
                      name="confirm_email_password"
                      id="confirm_email_password"
                      autocomplete="off"
                    />
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirm_email_password" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_signin_submit"
                  type="submit"
                  ref="updateEmailButton"
                  class="btn btn-sm btn-primary me-2 px-6"
                >
                  <span class="indicator-label"> 
                    {{ $t('pages.account.layoutSettings.changeEmail') }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t('buttons.wait') }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button
                  id="kt_signin_cancel"
                  type="button"
                  class="btn btn-sm btn-color-gray-500 btn-active-light-primary px-6"
                  @click="emailFormDisplay = !emailFormDisplay"
                >
                  {{ $t('buttons.cancel') }}
                </button>
              </div>
            </VForm>
            <!--end::Form-->
          </div>
          <div
            id="kt_signin_email_button"
            :class="{ 'd-none': emailFormDisplay }"
            class="ms-auto"
          >
            <button
              class="btn btn-sm btn-light"
              @click="emailFormDisplay = !emailFormDisplay"
            >
              <template v-if="isMobile">
                {{ $t('buttons.change') }}
              </template>
              <template v-else>
                {{ $t('pages.account.layoutSettings.changeEmail') }}
              </template>
            </button>
          </div>
        </div>
        <!--end::Email Address-->
        
        <div class="separator my-5"></div>

        <!--begin::Password-->
        <div class="d-flex flex-wrap align-items-center mb-0">
          
          <div :class="['symbol me-6', { 'd-none': passwordFormDisplay }]">
            <svg class="stroke-gray-300 fill-gray-100" fill="none" height="48" viewbox="0 0 44 48" width="44" xmlns="http://www.w3.org/2000/svg">
              <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
              18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
              39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z" fill="">
              </path>
              <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
              18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
              39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z" stroke="">
              </path>
            </svg>
            <div class="symbol-badge badge top-50 start-50">
              <i class="ki-outline ki-shield-tick fs-1 text-gray-500"></i>
            </div>
          </div>

          <div id="kt_signin_password" :class="['d-flex flex-column me-5', { 'd-none': passwordFormDisplay }]">
            <div class="fs-5 text-gray-900 fw-bold">{{ $t('pages.authentication.password') }}</div>
            <div class="fs-6 fw-semibold text-gray-500">************</div>
          </div>
          
          <div
            id="kt_signin_password_edit"
            class="flex-row-fluid"
            :class="{ 'd-none': !passwordFormDisplay }"
          >
            <!--begin::Form-->
            <VForm
              id="kt_signin_change_password"
              class="form"
              novalidate
              @submit="onSubmitChangePassword"
              :validation-schema="changePasswordSchema"
            >
              <div class="row mb-6">
                <div class="col-lg-4">
                  <div class="fv-row mb-4" data-kt-password-meter="true">
                    <label
                      for="current_password"
                      class="form-label fs-6 fw-bold mb-3"
                    >
                      {{ $t('pages.account.layoutSettings.currentPassword') }}
                    </label>
                    <div class="position-relative">
                      <Field
                        type="password"
                        class="form-control form-control-lg form-control-solid fw-semibold fs-6"
                        name="current_password"
                        id="current_password"
                        autocomplete="off"
                      />
                      <!--begin::Visibility toggle-->
                      <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility">
                        <KTIcon icon-name="eye-slash" icon-class="fs-1" />
                        <KTIcon icon-name="eye" icon-class="fs-1 d-none" />
                      </span>
                      <!--end::Visibility toggle-->
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="current_password" />
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4">
                  <div class="fv-row" data-kt-password-meter="true">
                    <!--begin::Wrapper-->
                    <div class="mb-3">
                      <label
                        for="new_password"
                        class="form-label fs-6 fw-bold mb-3"
                      >
                        {{ $t('pages.account.layoutSettings.newPassword') }}
                      </label>
                      <div class="position-relative">
                        <Field
                          type="password"
                          class="form-control form-control-lg form-control-solid fw-semibold fs-6"
                          name="new_password"
                          id="new_password"
                          autocomplete="off"
                          :validate-on-input="false"
                        />
                        <!--begin::Visibility toggle-->
                        <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                            data-kt-password-meter-control="visibility">
                          <KTIcon icon-name="eye-slash" icon-class="fs-1" />
                          <KTIcon icon-name="eye" icon-class="fs-1 d-none" />
                        </span>
                        <!--end::Visibility toggle-->
                      </div>
                      <div class="fv-plugins-message-container">
                        <div class="fv-help-block">
                          <ErrorMessage name="new_password" />
                        </div>
                      </div>
                    </div>
                    <!--begin::Meter-->
                    <div
                      class="d-flex align-items-center mb-3"
                      data-kt-password-meter-control="highlight"
                    >
                      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2"></div>
                      <div class="flex-grow-1 bg-secondary bg-active-success rounded h-5px"></div>
                    </div>
                    <!--end::Meter-->
                  </div>
                  <!--end::Wrapper-->
                  <!--begin::Hint-->
                  <div class="text-muted mb-4">
                    {{ $t('pages.authentication.signUp.textPassword')}}
                  </div>
                  <!--end::Hint-->
                </div>
                <div class="col-lg-4">
                  <div class="fv-row mb-0" data-kt-password-meter="true">
                    <label
                      for="confirm_password"
                      class="form-label fs-6 fw-bold mb-3"
                    >
                      {{ $t('pages.account.layoutSettings.confirmNewPassword') }}
                    </label>
                    <div class="position-relative">
                      <Field
                        type="password"
                        class="form-control form-control-lg form-control-solid fw-semibold fs-6"
                        name="confirm_password"
                        id="confirm_password"
                        autocomplete="off"
                      />
                      <!--begin::Visibility toggle-->
                      <span class="btn btn-sm btn-icon position-absolute translate-middle top-50 end-0 me-n2"
                          data-kt-password-meter-control="visibility">
                        <KTIcon icon-name="eye-slash" icon-class="fs-1" />
                        <KTIcon icon-name="eye" icon-class="fs-1 d-none" />
                      </span>
                      <!--end::Visibility toggle-->
                    </div>
                    <div class="fv-plugins-message-container">
                      <div class="fv-help-block">
                        <ErrorMessage name="confirm_password" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="d-flex">
                <button
                  id="kt_password_submit"
                  type="submit"
                  ref="updatePasswordButton"
                  class="btn btn-sm btn-primary me-2 px-6"
                >
                  <span class="indicator-label">
                    {{ $t('pages.account.layoutSettings.updatePassword') }}
                  </span>
                  <span class="indicator-progress">
                    {{ $t('buttons.wait') }}
                    <span class="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                </button>
                <button
                  id="kt_password_cancel"
                  type="button"
                  @click="passwordFormDisplay = !passwordFormDisplay"
                  class="btn btn-sm btn-color-gray-500 btn-active-light-primary px-6"
                >
                  {{ $t('buttons.cancel') }}
                </button>
              </div>
            </VForm>
            <!--end::Form-->
          </div>
          <div
            id="kt_signin_password_button"
            class="ms-auto"
            :class="{ 'd-none': passwordFormDisplay }"
          >
            <button
              @click="passwordFormDisplay = !passwordFormDisplay"
              class="btn btn-sm btn-light"
            >
              <template v-if="isMobile">
                {{ $t('buttons.update') }}
              </template>
              <template v-else>
                {{ $t('pages.account.layoutSettings.updatePassword') }}
              </template>
            </button>
          </div>
        </div>
        <!--end::Password-->
      </div>
      <!--end::Card body-->
    </div>
    <!--end::Content-->
  </div>
  <!--end::Sign-in Method-->
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, nextTick, computed, watch, watchEffect } from "vue";
import { useAuthStore } from "@/stores/auth";
import { useI18n } from "vue-i18n";
import { isMobile } from "@/core/helpers/mobile";
import { PasswordMeterComponent } from "@/assets/ts/components";

import { ErrorMessage, Field, Form as VForm } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";

export default defineComponent({
  name: "account-settings-signIn-method",
  components: {
    ErrorMessage,
    Field,
    VForm,
  },
  setup() {
    const { t } = useI18n();
    const userStore = useAuthStore();
    
    const updateEmailButton = ref<HTMLElement | null>(null);
    const updatePasswordButton = ref<HTMLElement | null>(null);

    const emailFormDisplay = ref(false);
    const passwordFormDisplay = ref(false);

    const current_email_address = computed(() => userStore.user?.email);

    // Функция для создания схемы Yup с учетом текущего email
    const createChangeEmailSchema = (currentEmail: string) => {
      return Yup.object().shape({
        email_address: Yup.string()
          .required(t('validation.requiredField', { field: t('pages.authentication.email') }))
          .email(t('validation.email'))
          .min(6, t('validation.min', { min: 6 }))
          .notOneOf([currentEmail], t('validation.emailMustBeDifferent')),
        confirm_email_password: Yup.string()
          .required(t('validation.requiredField', { field: t('pages.authentication.password') })),
      });
    };

    // Референс на схему, который будет обновляться
    const changeEmailSchema = ref(createChangeEmailSchema(current_email_address.value));
    
    const createChangePasswordSchema = () => {
      return Yup.object().shape({
        current_password: Yup.string()
          .required(t('validation.required'))
          .label(t('pages.account.layoutSettings.currentPassword')),
        new_password: Yup.string()
          .required(t('validation.required'))
          .notOneOf([Yup.ref("current_password")], t('validation.passwordMustBeDifferent'))
          .min(8, t('validation.min', { min: 8 })),
        confirm_password: Yup.string()
          .oneOf([Yup.ref("new_password")], t('validation.passwordsMustMatch'))
          .required(t('validation.required')),
      });
    };
    
    const changePasswordSchema = computed(() => createChangePasswordSchema());
    
    const onSubmitChangeEmail = async (values: any) => {
      values = values as string;

      if (updateEmailButton.value) {
        // Активируем индикатор загрузки
        updateEmailButton.value.setAttribute("data-kt-indicator", "on");
        updateEmailButton.value.setAttribute("disabled", "disabled");
    
        // Отправляем запрос на восстановление пароля
        await userStore.changeEmailAcc(values);
        const error = Object.values(userStore.errors);

        if (error.length === 0) {
          // Скрываем форму
          emailFormDisplay.value = false;

          Swal.fire({
            text: t('messages.text.success.emailChange'),
            icon: "success",
            confirmButtonText: t('messages.button.okGotIt'),
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
        } else {
          Swal.fire({
            text: error[0] as string,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t('messages.button.tryAgain'),
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semibold btn-light-danger",
            },
          }).then(() => {
            userStore.errors = [];
          });
        }

        // Деактивируем индикатор загрузки
        updateEmailButton.value?.removeAttribute("disabled");
        updateEmailButton.value?.removeAttribute("data-kt-indicator");
      }
    };
    
    const onSubmitChangePassword = async (values: any) => {
      values = values as string;

      if (updatePasswordButton.value) {
        // Активируем индикатор загрузки
        updatePasswordButton.value.setAttribute("data-kt-indicator", "on");
        updatePasswordButton.value.setAttribute("disabled", "disabled");
        
        // Отправляем запрос на восстановление пароля
        await userStore.changePasswordAcc(values);
        const error = Object.values(userStore.errors);
    
        if (error.length === 0) {
          // Скрываем форму
          passwordFormDisplay.value = false;
        
          Swal.fire({
            text: t('messages.text.success.passwordChange'),
            icon: "success",
            confirmButtonText: t('messages.button.okGotIt'),
            buttonsStyling: false,
            heightAuto: false,
            customClass: {
              confirmButton: "btn btn-light-primary",
            },
          });
        } else {
          Swal.fire({
            text: error[0] as string,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: t('messages.button.tryAgain'),
            heightAuto: false,
            customClass: {
              confirmButton: "btn fw-semibold btn-light-danger",
            },
          }).then(() => {
            userStore.errors = [];
          });
        }
          
        // Деактивируем индикатор загрузки
        updatePasswordButton.value?.removeAttribute("disabled");
        updatePasswordButton.value?.removeAttribute("data-kt-indicator");
      }
    };
    
    onMounted(() => {      
      nextTick(() => {
        PasswordMeterComponent.bootstrap();
      });
    });

    // Отслеживаем изменения в current_email_address и пересоздаем схему
    watch(current_email_address, (newEmail) => {
      changeEmailSchema.value = createChangeEmailSchema(newEmail);
    });

    // Автоматическое пересоздание схемы при изменении локали
    watchEffect(() => {
      changeEmailSchema.value = createChangeEmailSchema(current_email_address.value);
    });    

    return {
      isMobile,
      userStore,
      changeEmailSchema,
      changePasswordSchema,
      updateEmailButton,
      updatePasswordButton,
      onSubmitChangeEmail,
      onSubmitChangePassword,
      emailFormDisplay,
      passwordFormDisplay,
    };
  },
});
</script>
