<template>
  <div class="d-flex flex-row"> 
    <router-link
      v-if="hasGroup"
      :to="{ name: 'search', query: { group: item.group } }"
      class="d-flex align-items-center text-gray-500 text-hover-primary"
    >
      <KTIcon icon-name="abstract-26" icon-class="fs-4 me-2" />
      <span class="text-nowrap">
        {{ groupTitle }}
      </span>
    </router-link>
    
    <div v-else class="d-flex align-items-center text-gray-400">
      <KTIcon icon-name="abstract-26" icon-class="fs-4 me-2 text-gray-400" />
      <span class="text-nowrap fs-7">
        {{ $t('pages.items.groupUnspecified') }}
      </span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed } from 'vue';

export default defineComponent({
  name: 'group-link',
  props: {
    item: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const getGroupTitle = (groupId) => {
        const group = props.group.find(p => p.id === groupId);
        return group ? group.title : '';
    };

    const hasGroup = computed(() => props.item.group > 0 && getGroupTitle(props.item.group));
    const groupTitle = computed(() => getGroupTitle(props.item.group));

    return {
      hasGroup,
      groupTitle,
    };
  },
});
</script>
