<template>
  <div @click="confirmAction">
    <slot></slot>
  </div>
</template>

<script lang="ts" setup>
import { inject } from 'vue';
import { useI18n } from "vue-i18n";
import { useItemStore } from "@/stores/item";
import { addToast } from '@/utils/toastManager';
import Swal from "sweetalert2/dist/sweetalert2.js";

// Определение пропсов
const props = defineProps({
  data: {
    type: Object,
    required: true,
  },
});

const { t } = useI18n();
const itemStore = useItemStore();

// Функция для подтверждения действия удаления
const confirmAction = () => {
  Swal.fire({
    text: t('messages.text.warning.markPurchased', { title: props.data.title }),
    icon: 'success',
    input: 'text',
    inputValue: props.data.price_purchase > 0 ? props.data.price_purchase : '',
    inputPlaceholder: t('pages.searchId.swal.purchaseMark.inputPlaceholder'),
    inputValidator: (value) => {
      const trimmedValue = value.trim().replace(/\s+/g, ''); // Удаляем пробелы
      if (!trimmedValue || trimmedValue <= 0) {
        return t('messages.text.error.markPurchasedEmpty');
      } else if (!/^\d+$/.test(trimmedValue)) {
        return t('messages.text.error.markPurchasedOnlyNumbers');
      }
    },
    buttonsStyling: false,
    showCancelButton: true,
    confirmButtonText: t('buttons.save'),
    cancelButtonText: t('messages.button.noCancel'),
    heightAuto: false,
    customClass: {
      confirmButton: 'btn btn-success',
      cancelButton: 'btn btn-secondary',
    },
  }).then(async (result) => {
    if (result.isConfirmed) {
      try {
        const cleanedValue = result.value?.length ? result.value.trim().replace(/\s+/g, '') : 0;
        await itemStore.markSearchPurchased({ id: props.data.id, price: cleanedValue });

        if (!Object.keys(itemStore.errors).length) {
          itemStore.fetchSearchPartData({ id: props.data.id });
          addToast(t('messages.any.success'), t('messages.text.success.markPurchased'), 'success');
        } else {
          handleError(itemStore.errors);
        }
      } catch (error) {
        handleError(error)
      }
    }
  });
};

// Константа для обработки ошибок
const handleError = (error) => {
  Swal.fire({
    text: error,
    icon: "error",
    buttonsStyling: false,
    confirmButtonText: t('messages.button.okGotIt'),
    heightAuto: false,
    customClass: {
      confirmButton: "btn btn-danger",
    },
  }).then(() => {
    itemStore.errors = [];
  });
}
</script>
