<template>
  <!--begin::Navbar-->
  <div class="app-navbar flex-shrink-0">
    <!--begin::isOnline-->
    <div class="app-navbar-item ms-2 ms-md-3">
      <div
        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom"
        :class="[
          'btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px',
          isOnline ? 'd-none' : ''
        ]"
      >
        <KTIcon icon-name="tech-wifi" icon-class="fs-2" />
      </div>
      <div
        class="menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-auto"
        data-kt-menu="true"
        data-kt-element="theme-mode-menu"
      >
        <div class="d-flex align-items-center ps-6 pe-8 my-0 mx-auto text-gray-700">
          <KTIcon icon-name="router" icon-class="fs-2 pe-5" />
          {{ $t('offline') }}
        </div>
      </div>
    </div>
    <!--end::isOnline-->

    <!--begin::Support Chaport-->
    <div class="app-navbar-item ms-2 ms-md-3">
      <div
        v-if="!isChaportLoading"
        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
        @click="handleChaportClick"
      >
        <KTIcon icon-name="support-24" icon-class="fs-1 mt-1" />
      </div>
    </div>
    <!--end::Support Chaport-->

    <!--begin::Notify-->
    <div class="app-navbar-item ms-2 ms-md-3">
      <!--begin::Drawer toggle-->
      <div
        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px position-relative"
        id="kt_notify_toggle"
        @click="resetIsLooked"
      >
        <KTIcon icon-name="notification-status" icon-class="fs-2" />
        <span
          v-if="notifyStore.isLooked"
          class="bullet bullet-dot bg-success h-6px w-6px position-absolute translate-middle top-0 start-50 animation-blink"
        ></span>
      </div>
      <!--end::Drawer toggle-->
    </div>
    <!--end::Notify-->

    <!--begin::Theme mode-->
    <div class="app-navbar-item ms-2 ms-md-3">
      <!--begin::Menu toggle-->
      <div
        class="btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-primary w-35px h-35px"
        data-kt-menu-trigger="{default:'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <KTIcon
          v-if="themeMode === 'light'"
          icon-name="night-day"
          icon-class="fs-2"
        />
        <KTIcon v-else icon-name="moon" icon-class="fs-2" />
      </div>
      <!--begin::Menu toggle-->
      <KTThemeModeSwitcher />
    </div>
    <!--end::Theme mode-->
    
    <!--begin::User menu-->
    <div class="app-navbar-item ms-2 ms-md-4" id="kt_header_user_menu_toggle">
      <!--begin::Menu wrapper-->
      <div
        class="cursor-pointer symbol symbol-35px"
        data-kt-menu-trigger="{default: 'click', lg: 'hover'}"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
      >
        <span 
          v-if="userStore.user.photo_url"
          class="symbol-label bg-dark text-inverse-info" 
          :style="`background-image: url('${ getAssetUrl(userStore.user.photo_url) }')`"
        ></span>
          <span v-else-if="userStore.user.name" class="symbol-label bg-dark text-inverse-info">
          {{ userStore.user.name.slice(0, 1).toUpperCase() }}
        </span>
        <span v-else-if="userStore.user.email" class="symbol-label bg-dark text-inverse-info">
          {{ userStore.user.email.slice(0, 1).toUpperCase() }}
        </span>
        <span v-else class="symbol-label bg-dark text-inverse-info">H</span>
      </div>
      <KTUserMenu />
      <!--end::Menu wrapper-->
    </div>
    <!--end::User menu-->
    
    <!--begin::Header menu toggle-->
    <div class="app-navbar-item d-none d-sm-flex d-lg-none ms-2 me-0">
      <div
        class="btn btn-flex btn-icon btn-active-color-primary w-40px h-30px"
        id="kt_app_header_menu_toggle"
      >
        <KTIcon icon-name="abstract-14" icon-class="fs-1 fs-sm-2" />
      </div>
    </div>
    <!--end::Header menu toggle-->
  </div>
  <!--end::Navbar-->
</template>

<script lang="ts">
import { getAssetPath, getAssetUrl } from "@/core/helpers/assets";
import { defineComponent, computed } from "vue";
import KTUserMenu from "@/layouts/default-layout/components/menus/UserAccountMenu.vue";
import KTThemeModeSwitcher from "@/layouts/default-layout/components/theme-mode/ThemeModeSwitcher.vue";
import { ThemeModeComponent } from "@/assets/ts/layout";
import { useThemeStore } from "@/stores/theme";
import { useAuthStore } from "@/stores/auth";
import { useNotifyStore } from '@/stores/notify';
import { useOnline } from '@vueuse/core';
import { isChaportLoading, handleChaportClick } from "@/core/plugins/chaport";

export default defineComponent({
  name: "header-navbar",
  components: {
    KTUserMenu,
    KTThemeModeSwitcher,
  },
  setup() {
    const themeStore = useThemeStore();
    const userStore = useAuthStore();
    const notifyStore = useNotifyStore();

    // Получаем реактивное состояние подключения
    const isOnline = useOnline();

    const themeMode = computed(() => {
      if (themeStore.mode === "system") {
        return ThemeModeComponent.getSystemMode();
      }
      return themeStore.mode;
    });
    
    const resetIsLooked = () => {
      notifyStore.setLooked(false);
      notifyStore.checkView();
    };
    
    return {
      userStore,
      themeMode,
      getAssetPath,
      getAssetUrl,
      notifyStore,
      resetIsLooked,
      isOnline,
      isChaportLoading,
      handleChaportClick,
    };
  },
});
</script>
