<template>
  <div
    v-if="shouldRenderCard"
    :class="{
      'card min-w-125px py-3 px-4': text,
      'flex-fill': isMobile
    }"
  >
    <div :class="[
      'd-flex flex-wrap mb-auto',
      isMobile ? 'gap-4' : 'gap-3',
      classCard
    ]">
      <div 
        v-for="(item, index) in renderItems" 
        :key="index" 
        class="symbol text-hover-primary"
        v-tooltip
        data-bs-custom-class="tooltip-inverse"
        data-bs-html="true"
        :title="item.tooltip"
      >
        <svg 
          class='stroke-gray-300 fill-gray-100'
          fill="none" 
          height="40" 
          width="37" 
          viewBox="0 0 44 48" 
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 2.4641C19.7128 0.320509 24.2872 0.320508 28 2.4641L37.6506 8.0359C41.3634 10.1795 43.6506 14.141 43.6506 
          18.4282V29.5718C43.6506 33.859 41.3634 37.8205 37.6506 39.9641L28 45.5359C24.2872 47.6795 19.7128 47.6795 16 45.5359L6.34937 
          39.9641C2.63655 37.8205 0.349365 33.859 0.349365 29.5718V18.4282C0.349365 14.141 2.63655 10.1795 6.34937 8.0359L16 2.4641Z">
          </path>
          <path d="M16.25 2.89711C19.8081 0.842838 24.1919 0.842837 27.75 2.89711L37.4006 8.46891C40.9587 10.5232 43.1506 14.3196 43.1506 
          18.4282V29.5718C43.1506 33.6804 40.9587 37.4768 37.4006 39.5311L27.75 45.1029C24.1919 47.1572 19.8081 47.1572 16.25 45.1029L6.59937 
          39.5311C3.04125 37.4768 0.849365 33.6803 0.849365 29.5718V18.4282C0.849365 14.3196 3.04125 10.5232 6.59937 8.46891L16.25 2.89711Z">
          </path>
        </svg>
        <div class="symbol-badge badge top-50 start-50">
          <KTIcon 
            :icon-name="item.iconName"
            :class="['text-gray-600', isMobile ? 'fs-1' : 'fs-2']"
          />
        </div>
      </div>
    </div>
    <div
      v-if="text"
      :class="[
        'fw-semibold text-gray-500',
        isMobile ? 'mt-4' : 'mt-2'
      ]"
    >
      {{ text }}
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { isMobile } from "@/core/helpers/mobile";

export default defineComponent({
  name: 'filters-card',
  props: {
    data: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      default: () => ([]),
    },
    text: {
      type: String,
      required: false,
    },
    classCard: {
      type: String,
      required: false,
    },
  },
  computed: {
    shouldRenderCard() {
      return (
        (this.data.ai_status === '1' && this.data.ai_score) ||
        this.data.isDiscounted === '1' ||
        this.data.required_words ||
        this.data.excluded_words ||
        this.group.required_words ||
        this.group.excluded_words
      );
    },
    renderItems() {
      const combinedRequiredWords = this.processWords([
        this.group.required_words,
        this.data.required_words,
      ]);
      
      const combinedExcludedWords = this.processWords([
        this.group.excluded_words,
        this.data.excluded_words,
      ]);
      
      return [
        {
          condition: this.data.ai_status === '1' && this.data.ai_score,
          iconName: 'technology-4',
          tooltip: `${this.$t('modal.label.ai_score')}: ${this.formattedAiScore}`,
        },
        {
          condition: this.data.isDiscounted === '1',
          iconName: 'discount',
          tooltip: this.$t('modal.subLabel.discounted'),
        },
        {
          condition: combinedRequiredWords,
          iconName: 'tablet-ok',
          tooltip: `<div class="d-flex flex-wrap justify-content-center gap-2"><span class="w-100">${this.$t('pages.items.modal.label.requiredWords')}</span>` +
            combinedRequiredWords
              .split(',')
              .map(word => `<span class="badge badge-secondary fs-8 fw-semibold py-1 px-2">${word.trim()}</span>`)
              .join(' ') + `</div>`,
        },
        {
          condition: combinedExcludedWords,
          iconName: 'tablet-delete',
          tooltip: `<div class="d-flex flex-wrap justify-content-center gap-2"><span class="w-100">${this.$t('pages.items.modal.label.excludedWords')}</span>` +
            combinedExcludedWords
              .split(',')
              .map(word => `<span class="badge badge-secondary fs-8 fw-semibold py-1 px-2">${word.trim()}</span>`)
              .join(' ') + `</div>`,
        },
      ].filter(item => item.condition);
    },
    formattedAiScore() {
      const score = parseFloat(this.data.ai_score);
      return !isNaN(score) ? `${(score * 100).toFixed(0)}%` : '0%';
    },
  },
  methods: {
    processWords(data: (string | null | undefined)[]): string {
      return data
        .filter(word => word && word.trim()) // Убираем пустые или пробельные строки
        .join(',') // Объединяем строки через запятую
        .split(',') // Разделяем слова для обработки уникальных значений
        .map(word => word.trim()) // Убираем лишние пробелы вокруг слов
        .filter(Boolean) // Убираем возможные пустые строки
        .reduce((unique, word) => unique.includes(word) ? unique : [...unique, word], []) // Убираем дубли вручную
        .join(', '); // Объединяем обратно в строку
    },
  },
  setup() {
    return {
      isMobile: isMobile.value,
    };
  },
});
</script>