<template>
  {{ displayedValue }}{{ suffix }}
</template>

<script lang="ts" setup>
import { computed } from 'vue';
import { useTransition } from '@vueuse/core';

// Определяем props для компонента
const props = defineProps<{
  targetValue: number;
  suffix?: string;
  isLocalize?: boolean;
  isRound?: boolean;
}>();

// Используем плавный переход для анимации числа
const transitionValue = useTransition(
  computed(() => props.targetValue), {
    duration: 500, // Длительность анимации
  }
);

// Округляем значение до целого числа
const displayedValue = computed(() => {
  const value = props.isRound === true
    ? transitionValue.value.toFixed(2)
    : Math.round(transitionValue.value);

  return props.isLocalize ? value.toLocaleString('ru-RU') : value.toString();
});

// Устанавливаем суффикс (если передан), по умолчанию пустая строка
const suffix = computed(() => props.suffix ?? '');
</script>
